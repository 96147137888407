/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_guarantee: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M12 2a31.4 31.4 0 0 1-9 3.12C3 16 8.12 20.77 12 22c3.88-1.23 9-6 9-16.88A31.4 31.4 0 0 1 12 2zm0 17.86C10.1 19 5.51 16.07 5 6.72a31.8 31.8 0 0 0 7-2.49 31.8 31.8 0 0 0 7 2.49c-.51 9.35-5.1 12.28-7 13.14zm5.37-12c-.59 5.76-3 9-5.37 10.34-2.38-1.3-4.78-4.57-5.37-10.33a32.11 32.11 0 0 0 5.37-2 32.11 32.11 0 0 0 5.37 2z"/>',
  },
});
