/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_phone: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.93 17.4L20 19.75a2.45 2.45 0 0 1-1.36 1.36 2.74 2.74 0 0 1-1 .18 2.43 2.43 0 0 1-1-.2l-.92-.35a16.77 16.77 0 0 1-7.11-4.62 20.52 20.52 0 0 1-4.89-7.53c-.15-.36-.28-.69-.42-1a2.37 2.37 0 0 1 0-1.95 2.49 2.49 0 0 1 1.4-1.35l2.42-.88a2 2 0 0 1 1.53.06 2 2 0 0 1 1 1.11l1.59 4.24-2.66 2.58a20.65 20.65 0 0 0 2.24 2.6 19.35 19.35 0 0 0 2.63 2.26l2.63-2.52 3.55 1a2 2 0 0 1 1.24 1 1.92 1.92 0 0 1 .06 1.66z"/>',
  },
});
