import StoreAPI from 'shared/javascripts/api/stores';
import CouriersAPI from 'shared/javascripts/api/couriers';
import FeedbacksAPI from 'shared/javascripts/api/feedbacks';
import CartAPI from 'shared/javascripts/api/carts';
import { removeEmpty } from 'shared/javascripts/utils/object-helpers';
import defaultFilters from 'shared/data/default-filters';

export const minifyProducts = (products = []) =>
  products.map(product => ({
    id: product.id,
    name: product.name,
    min_quantity: product.min_quantity,
    images: product.images,
    deal: product.deal,
    category: product.category,
    condition: product.condition,
    wholesales: product.wholesales,
    rating: product.rating,
    price: product.price,
    url: product.url,
  }));

const initialState = () => ({
  isStoreLoading: true,
  store: {
    owner: {
      unfreezing: {},
    },
    closing: {},
  },
  brand: {},
  products: [],
  productsMeta: {},
  isProductsLoading: true,
  isBrandLabelsLoading: true,
  labels: [],
  brandLabels: [],
  filters: defaultFilters(),
  vouchers: [],
  couriers: [],
  feedbacks: {
    meta: {},
  },
  isCouriersLoading: true,
  cartItems: [],
  banners: {},
  isBannersLoading: true,
  persistedStoreUrl: '',
});

const getters = {
  isProductsEmpty: state => !state.products.length,
  isStoreFreezed: state => state.store.owner.unfreezing.permanent_frozen || state.store.owner.unfreezing.frozen,
  isStoreEligibleForSelfUnfreeze: state => state.store.owner.unfreezing.eligible,
  isStoreInactive: state =>
    state.store.closing.closed || state.store.owner.unfreezing.frozen || state.store.owner.unfreezing.permanent_frozen,
};

const mutations = {
  setState: (state, [key, value]) => {
    state[key] = value;
  },
  resetProducts(state) {
    state.filters = defaultFilters();
    state.isProductsLoading = true;
    state.products = [];
    state.productsMeta = {};
  },
  updateStore: (state, { store, labels }) => {
    state.store = store;
    state.labels = labels;
  },
  updateProducts: (state, { data, meta }) => {
    state.products = minifyProducts(data);
    state.productsMeta = meta;
    state.isProductsLoading = false;
  },
  updateFilters: (state, payload) => {
    state.filters = {
      ...state.filters,
      ...payload,
    };
  },
  updateLoadingProduct: (state, isLoading) => {
    state.isProductsLoading = isLoading;
  },
  updateLoadingStore: (state, isLoading) => {
    state.isStoreLoading = isLoading;
  },
  updateStoreVouchers: (state, payload) => {
    state.vouchers = payload;
  },
  updateLoadingCouriers: (state, isLoading) => {
    state.isCouriersLoading = isLoading;
  },
  updateCouriers: (state, { data }) => {
    state.couriers = data;
    state.isCouriersLoading = false;
  },
  updateStoreFeedbacks: (state, data) => {
    state.feedbacks = data;
    state.isStoreFeedbacksLoading = false;
  },
  appendProducts: (state, { data, meta }) => {
    state.products = [...state.products, ...minifyProducts(data)];
    state.productsMeta = meta;
  },
  updateCartItems: (state, { items }) => {
    state.cartItems = items;
  },
  updateBanners: (state, data) => {
    state.banners = data;
    state.isBannersLoading = false;
  },
  updateBrandLabels: (state, data) => {
    state.brandLabels = data;
    state.isBrandLabelsLoading = false;
  },
  updateBrand: (state, data) => {
    state.brand = data;
  },
};

const actions = {
  GET_STORE: async ({ commit }, storeId) => {
    const { data: store } = await StoreAPI.getStoreById(storeId);
    const { data: labels } = await StoreAPI.getStoreLabels(storeId, { has_product: true });
    commit('updateStore', { store, labels });
  },
  GET_BRAND: async ({ commit }, storeId) => {
    const { data } = await StoreAPI.getBrand(storeId);
    commit('updateBrand', data);
  },
  GET_STORE_PROFILE: async ({ state, commit }, username) => {
    const { labels } = state;
    const { data: store } = await StoreAPI.getStoreByUsername(username);
    commit('updateStore', { store, labels });
  },
  GET_LABELS: async ({ state, commit }) => {
    const { store } = state;
    const { data: labels } = await StoreAPI.getStoreLabels(store.id, { has_product: true });
    commit('updateStore', { store, labels });
  },
  GET_PRODUCTS: async ({ commit, state }, moreProduct) => {
    commit('updateLoadingProduct', !moreProduct);
    const response = await StoreAPI.getStoreProducts(
      state.store.id,
      removeEmpty({
        object: state.filters,
        removeEmptyString: true,
        removeEmptyArray: true,
      }),
    );
    if (response) {
      const { data, meta } = response;
      const commitProducts = moreProduct ? 'appendProducts' : 'updateProducts';
      commit(commitProducts, { data, meta });

      const { pathname, search } = window.location;
      const storeUrl = `${pathname}${search}`;
      commit('setState', ['persistedStoreUrl', storeUrl]);
    }
  },
  UPDATE_FILTERS: ({ commit }, filters) => {
    commit('updateFilters', filters);
  },
  GET_STORE_VOUCHERS: async ({ commit }, { id }) => {
    const response = await StoreAPI.getStorePremiumVouchers(id);
    if (response && response.data) {
      commit('updateStoreVouchers', response.data);
    }
  },
  GET_COURIERS: async ({ commit, state }) => {
    if (!state.couriers.length) {
      commit('updateLoadingCouriers', true);
      const { data, meta } = await CouriersAPI.getCouriers();
      commit('updateCouriers', { data, meta });
    }
  },
  UPDATE_STORE_STATUS: async (_, payload) => {
    const response = await StoreAPI.updateStoreStatus(payload.id, payload.data);
    return response;
  },
  GET_STORE_FEEDBACKS: async ({ commit, state }, query) => {
    state.isStoreFeedbacksLoading = true;
    const data = await FeedbacksAPI.getStoreFeedbacks(state.store.id, query);
    commit('updateStoreFeedbacks', data);
  },
  ADD_TO_CART: async (_, { query, identity }) => {
    const response = await CartAPI.addToCart(identity, query);
    return response;
  },
  RETRIEVE_CART: async ({ commit }, query) => {
    const response = await CartAPI.retrieveCart(query);
    commit('updateCartItems', response.data);
    return response;
  },
  GET_BANNERS: async ({ commit }, id) => {
    const response = await StoreAPI.getBrandBannerById(id);
    commit('updateBanners', response.data);
  },
  GET_BRAND_LABELS: async ({ commit }, id) => {
    const response = await StoreAPI.getBrandLabelsById(id);
    commit('updateBrandLabels', response.data);
  },
  GET_PRODUCTS_BY_ID: (_, { id, query }) => StoreAPI.getStoreProducts(id, query, false),
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
