<template>
  <div class="c-header c-header--fixed u-bg--white">
    <bl-field-mv class="spica-header-searchbar c-header__action u-mrgn-bottom--0 u-width-1">
      <bl-textfield-av
        clearable
        ref="keywordsInput"
        @keyup.enter.native="changeKeywords"
        :placeholder="placeholder"
        v-model="keywords"
      >
        <bl-icon-av class="bl-textfield-av__icon" slot="prefixIcon" name="ico_search" />
      </bl-textfield-av>
    </bl-field-mv>
    <div class="c-header__action u-1of8">
      <bl-button-av @click="$emit('back-clicked')" class="u-border--0 ut-fixedheader-back">
        <div slot="icon">
          <bl-icon-av name="ico_back_android" />
        </div>
      </bl-button-av>
    </div>
    <div class="c-header__action c-header__action--right u-1of8">
      <bl-button-av @click="$emit('open-menu-lapak')" class="u-border--0 ut-fixedheader-more">
        <div slot="icon">
          <bl-icon-av name="ico_more_vertical" />
        </div>
      </bl-button-av>
    </div>
  </div>
</template>

<script>
import { BlFieldMv, BlTextfieldAv, BlButtonAv } from '@bukalapak/bazaar-mweb';
import BlIconAv from 'shared/javascripts/components/BlIconAv';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageFixedHeader',
  components: {
    BlFieldMv,
    BlTextfieldAv,
    BlButtonAv,
    BlIconAv,
  },
  data() {
    return {
      searchBarOnFocus: false,
      keywords: '',
    };
  },
  created() {
    this.keywords = this.filters.keywords;
  },
  computed: {
    ...mapState('store', ['store', 'brand', 'filters']),
    storeName() {
      return (this.brand && this.brand.name) || this.store.name;
    },
    placeholder() {
      return `Cari di ${this.storeName || '...'}`;
    },
  },
  methods: {
    async changeKeywords() {
      await this.$store.dispatch('store/UPDATE_FILTERS', { keywords: this.keywords, offset: 0 });
      this.$emit('apply-filter');
    },
  },
};
</script>
<style lang="scss" scoped>
.spica {
  &-header {
    &-searchbar {
      padding-left: 44px;
      padding-right: 44px;
      .is-focused.bl-textfield {
        background: #fff !important;
      }
      .bl-textfield:not(.is-focused) {
        background: #f5f5f5;
        border: none;
      }
    }
  }
}
</style>
