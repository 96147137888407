<template>
  <div class="u-bg--white">
    <div class="u-pad-h--3 u-pad-v--4">
      <div class="u-mrgn-bottom--3">
        <span class="u-valign-top c-dualtone-icon c-dualtone-icon--medium c-dualtone-icon--promo u-mrgn-right--3" />
        <div class="u-display-inline-block">
          <div :class="textClass" class="u-mrgn-bottom--1 u-txt--small u-txt--bolder u-txt--uppercase">
            {{ voucherTitle }}
          </div>
          <div class="u-txt--large u-txt--bolder u-mrgn-bottom--1 ut-voucher-discount">
            {{ voucher.premium_voucher_type === 'percentage' ? `${percentageDiscount}%` : getDiscountAmount }}
          </div>
          <p v-if="voucher.premium_voucher_type === 'percentage'" class="u-txt--small u-mrgn-bottom--0">
            maksimal hingga {{ maxPercentageDiscount | money }}
          </p>
          <p v-if="voucher.premium_voucher_type === 'shipping'" class="u-txt--small u-mrgn-bottom--0">
            dengan pengiriman {{ allowedCourier }}
          </p>
        </div>
      </div>
      <div class="bl-flex-container u-mrgn-bottom--3">
        <div
          class="u-pad-v--2 u-border-bottom--1--sand-dark u-border-right--1--sand-dark u-border-top--1--sand-dark u-width-1of2"
        >
          <div :class="textClass" class="u-fg--ash-dark">
            ETALASE BARANG
          </div>
          <div class="ut-voucher-etalase-name c-voucher-name" :class="titleTextClass">
            {{ voucher.label ? voucher.label.name : 'Semua Barang' }}
          </div>
        </div>
        <div
          class="u-pad--2 u-border-bottom--1--sand-dark u-border-bottom--1--sand-dark u-border-top--1--sand-dark u-width-1of2"
        >
          <div :class="textClass" class="u-fg--ash-dark">
            MINIMUM TRANSAKSI
          </div>
          <div :class="titleTextClass" class="ut-voucher-min-amount">
            {{ getMinimalAmount }}
          </div>
        </div>
      </div>
      <div :class="titleTextClass">
        <div class="u-txt--bold u-txt--small-upcase u-pad--2 u-border--1--sand-dark u-width-1">
          <span class="ut-voucher-code">{{ voucher.voucher_code }}</span>
          <span @click="copyCode" class="u-pointer u-fg--red-brand u-float-right">
            <span class="c-m-icon c-m-icon--small c-m-icon--copy u-mrgn-right--1 u-valign-top" />
            salin
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { money } from '../../utils/number-helper';
import { copyTextToClipboard } from '../../utils/clipboard-helpers';

export default {
  name: 'RevampVoucherMobile',
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'feed'].indexOf(value) > -1;
      },
    },
  },
  computed: {
    getDiscountAmount() {
      return money(this.voucher.amount, 0, 'Rp', '.', ',');
    },
    getMinimalAmount() {
      return money(this.voucher.min_purchase, 0, 'Rp', '.', ',');
    },
    textClass() {
      return this.type === 'normal' ? 'u-txt--base' : 'u-txt--tiny';
    },
    titleTextClass() {
      return this.type === 'normal' ? '' : 'u-txt--small';
    },
    voucherTitle() {
      if (this.voucher.premium_voucher_type === 'percentage') {
        return 'Diskon';
      }
      if (this.voucher.premium_voucher_type === 'shipping') {
        return 'Gratis Ongkir';
      }
      return 'Potongan Harga';
    },
    maxPercentageDiscount() {
      return (this.voucher.percentage_setting && this.voucher.percentage_setting.maximum_discount_amount) || 0;
    },
    percentageDiscount() {
      return (this.voucher.percentage_setting && this.voucher.percentage_setting.percentage_value) || 0;
    },
    allowedCourier() {
      return (this.voucher.shipping_setting && this.voucher.shipping_setting.allowed_courier.join()) || '';
    },
  },
  methods: {
    async copyCode() {
      if (await copyTextToClipboard(this.voucher.voucher_code)) {
        this.$emit('code-copied', this.voucher.voucher_code);
        this.$BLFlash.show('Kode berhasil disalin', 'success');
      } else {
        this.$BLFlash.show('Kode gagal disalin', 'error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-voucher-name {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
