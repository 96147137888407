<template>
  <bl-card-mv>
    <revamp-product-card-head :product="product" :query="query" />
    <revamp-product-card-body
      :product="product"
      :is-own-product="isOwnProduct"
      :keywords="keywords"
      :is-loading="isLoading"
      :query="query"
      @add-to-cart="$emit('add-to-cart')"
    />
  </bl-card-mv>
</template>

<script>
import { BlCardMv } from '@bukalapak/bazaar-mweb';
import RevampProductCardBody from './RevampProductCardBody';
import RevampProductCardHead from './RevampProductCardHead';

export default {
  name: 'RevampProductCardMobile',
  components: {
    BlCardMv,
    RevampProductCardBody,
    RevampProductCardHead,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isOwnProduct: {
      type: Boolean,
      default: false,
    },
    keywords: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
      default: '',
    },
  },
};
</script>

<style src="./RevampProductCardMobile.style.scss" lang="scss" scoped></style>
