import ProductHighlightsAPI from 'shared/javascripts/api/products-highlights';

const ACTIVE_STATE = 'active';
const initialState = () => ({
  productHighlights: [],
  selectedHighlights: null,
  isHighlightsLoading: true,
});

const getters = {};

const mutations = {
  updateProductHighlights: (state, payload) => {
    state.productHighlights = payload
      .filter(e => e.state === ACTIVE_STATE && e.products.length)
      .sort((a, b) => a.order > b.order);
    if (state.productHighlights && state.productHighlights.length > 0) {
      state.selectedHighlights = state.productHighlights[0];
    }
    state.isHighlightsLoading = false;
  },
  updateSelectedHighlights: (state, payload) => {
    state.selectedHighlights = payload;
  },
  updateLoadingHighlights: (state, isLoading) => {
    state.isHighlightsLoading = isLoading;
  },
};

const actions = {
  GET_PRODUCT_HIGHLIGHTS: async ({ commit }, id) => {
    const { data } = await ProductHighlightsAPI.getProductHighlights(id);
    commit('updateProductHighlights', data);
  },
  SET_SELECTED_PH: ({ commit }, item) => {
    commit('updateSelectedHighlights', item);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
