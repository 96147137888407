/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_target: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M6 12a6.1 6.1 0 0 1 .21-1.55L8 12.27A4 4 0 1 0 12.27 8l-1.82-1.79A6.1 6.1 0 0 1 12 6a6 6 0 1 1-6 6zm6-10a10 10 0 0 0-3.52.65l.79.79a2.6 2.6 0 0 1 .56.87A7.8 7.8 0 0 1 12 4a8 8 0 1 1-8 8 8.24 8.24 0 0 1 .26-2h-.09l-.58-.59-.94-.93A10 10 0 1 0 12 2zm0 8a2.09 2.09 0 0 0-.51.07L8 6.59V5L5.85 2.85a.5.5 0 0 0-.85.36V5H3.21a.5.5 0 0 0-.36.85l2 2a.49.49 0 0 0 .36.15h1.38l3.48 3.49A2.09 2.09 0 0 0 10 12a2 2 0 1 0 2-2z"/>',
  },
});
