/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_chevron_calendar_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M17 4v2H3V4a1 1 0 0 1 1-1h2v-.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V3h4v-.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V3h2a1 1 0 0 1 1 1zm0 4v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V8zm-2 4.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5z"/>',
  },
});
