<template>
  <div class="spica-c-jumbotron c-jumbotron u-position-relative u-pad--0">
    <img
      v-if="!isStoreLoading"
      class="u-width-1 u-height-1 lazyload"
      :src="store.header_image.url"
      :alt="store.name"
      onError="this.onerror=null;this.src='https://s0.bukalapak.com/images/header-lapak-default.jpg';"
    />
    <img v-else :src="defaultHeader" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import defaultHeader from './data/image';

export default {
  name: 'MerchantPageBanner',
  data() {
    return {
      defaultHeader,
    };
  },
  computed: {
    ...mapState('store', ['store', 'isStoreLoading']),
  },
};
</script>

<style lang="scss" scoped>
.spica-c-jumbotron {
  overflow: hidden;
  height: calc(100vw * 1 / 3.6);
  &__overlay {
    z-index: 1;
    opacity: 0.33;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
