/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_bill: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M18.5 2h-13A1.5 1.5 0 0 0 4 3.5V22l2.5-2L9 22l3-2 3 2 2.5-2 2.5 2V3.5A1.5 1.5 0 0 0 18.5 2zM11 15H8a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2zm5-4H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2zm0-4H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/>',
  },
});
