/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_share_android: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21 19a3 3 0 0 1-6 0 2.6 2.6 0 0 1 .06-.56l-7.13-4.16a3 3 0 1 1 0-4.56l7.13-4.16A2.6 2.6 0 0 1 15 5a3 3 0 1 1 1.07 2.28l-7.13 4.16a2.64 2.64 0 0 1 0 1.12l7.13 4.16A3 3 0 0 1 21 19z"/>',
  },
});
