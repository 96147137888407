/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_history_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M12 12a1 1 0 0 1-.6-.2l-2-1.5a1 1 0 0 1-.4-.8V7a1 1 0 0 1 2 0v2l1.6 1.2a1 1 0 0 1 .2 1.4 1 1 0 0 1-.8.4zM10.5 2a7.54 7.54 0 0 0-7.34 6H1l3 4 3-4H5.22a5.5 5.5 0 1 1 5.28 7 5.44 5.44 0 0 1-4-1.69 1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.41A7.41 7.41 0 0 0 10.5 17a7.5 7.5 0 0 0 0-15z"/>',
  },
});
