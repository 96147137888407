<template>
  <div v-if="!isCouriersLoading" class="c-link-list u-mrgn-bottom--10">
    <div v-for="(courier, index) in couriers" :key="index" class="c-link-list__item u-pad-v--4 u-pad-right--2">
      <bl-checkbox-av v-model="productCouriersData" :native-value="courier.name">
        {{ courier.name }}
      </bl-checkbox-av>
    </div>
  </div>
</template>

<script>
import { BlCheckboxAv } from '@bukalapak/bazaar-mweb';
import FilterCouriers from 'shared/javascripts/mixins/filter/couriers';

export default {
  name: 'FilterCouriers',
  components: {
    BlCheckboxAv,
  },
  mixins: [FilterCouriers],
};
</script>
