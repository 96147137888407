/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_history: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M14 9v2.59l1.71 1.7a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0l-2-2A1 1 0 0 1 12 12V9a1 1 0 0 1 2 0zm-1-6a9 9 0 0 0-8.94 8H2l3 4 3-4H6.08a7 7 0 1 1 2.3 6.25 1 1 0 0 0-1.38.09 1 1 0 0 0 .09 1.41A9 9 0 1 0 13 3z"/>',
  },
});
