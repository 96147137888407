/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_search_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M17.71 16.29l-4-4a6.52 6.52 0 1 0-1.41 1.41l4 4a1 1 0 0 0 1.42 0 1 1 0 0 0-.01-1.41zM8.5 13A4.5 4.5 0 1 1 13 8.5 4.51 4.51 0 0 1 8.5 13z"/>',
  },
});
