/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_graph: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21 19.5v1a.5.5 0 0 1-.5.5h-16A1.5 1.5 0 0 1 3 19.5v-16a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v12.73l2.41-4.71a1.51 1.51 0 0 1 2-.65L13 12.7a.5.5 0 0 0 .67-.22L16 8l-2.39-1.23a.5.5 0 0 1 .07-.92l5-1.62a.5.5 0 0 1 .63.32l1.62 5a.5.5 0 0 1-.7.6L17.75 8.9 15 14.28a1.5 1.5 0 0 1-2 .65l-3.59-1.82a.5.5 0 0 0-.68.22L5.83 19H20.5a.5.5 0 0 1 .5.5z"/>',
  },
});
