/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_home: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20 11h-1v8.92A1.08 1.08 0 0 1 17.91 21H14.5a.5.5 0 0 1-.5-.5V16a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v4.5a.5.5 0 0 1-.5.5H6.09A1.08 1.08 0 0 1 5 19.92V11H4a1 1 0 0 1-.61-1.85l8-5.95a1 1 0 0 1 1.18 0l8 6A1 1 0 0 1 20 11z"/>',
  },
});
