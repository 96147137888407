/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_verified: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21.53 10.86L20 9.33V6.16a1.66 1.66 0 0 0-1.66-1.66h-3.17l-2-2a1.61 1.61 0 0 0-2.28 0l-2 2H5.66A1.66 1.66 0 0 0 4 6.16v3.17l-1.53 1.53a1.61 1.61 0 0 0 0 2.28L4 14.67v3.17a1.66 1.66 0 0 0 1.66 1.66h3.17l2 2a1.61 1.61 0 0 0 2.28 0l2-2h3.17A1.66 1.66 0 0 0 20 17.84v-3.17l1.53-1.53a1.61 1.61 0 0 0 0-2.28zm-10.3 4.91a.58.58 0 0 1-.94 0L7 12.14l.83-1L10.75 13l5.42-4.76.83 1z"/>',
  },
});
