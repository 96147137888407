<template>
  <div>
    <div @click="changeLabel" class="u-mrgn-bottom--6 ut-etalase-default">
      <span :class="{ 'u-txt--bold': !filters.label_id }">Semua Barang</span>
      <bl-icon-av v-if="!filters.label_id" class="u-float-right u-valign-top" name="ico_check_minor" color="crimson" />
    </div>
    <div
      v-for="(label, idx) in labels"
      :key="`label-${label.id}`"
      :class="`${idx + 1 === labels.length ? 'u-mrgn-bottom--2' : 'u-mrgn-bottom--6'} ut-etalase-${label.id}`"
      @click="changeLabel(label)"
    >
      <span :class="{ 'u-txt--bold': label.id === filters.label_id }">{{ label.name }}</span>
      <bl-icon-av
        v-if="label.id === filters.label_id"
        class="u-float-right u-valign-top"
        name="ico_check_minor"
        color="crimson"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BlIconAv from 'shared/javascripts/components/BlIconAv';

export default {
  name: 'MerchantPageEtalase',
  components: {
    BlIconAv,
  },
  computed: {
    ...mapState('store', ['labels', 'filters']),
  },
  methods: {
    async changeLabel(label) {
      const labelId = label && label.id ? label.id : '';
      await this.$store.dispatch('store/UPDATE_FILTERS', { label_id: labelId, offset: 0 });
      this.$emit('apply-filter');
    },
  },
};
</script>
