/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_info_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 1a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm1 14H9V9h2zm0-8H9V5h2z"/>',
  },
});
