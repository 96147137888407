/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_download: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M5.26 11.62A1 1 0 0 1 6 10h3l.84-6.69A1.49 1.49 0 0 1 11.32 2h1.36a1.49 1.49 0 0 1 1.48 1.31L15 10h3a1 1 0 0 1 .78 1.62l-5.58 5.89a1.62 1.62 0 0 1-2.32 0zM19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2z"/>',
  },
});
