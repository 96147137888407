/* eslint-disable no-undef */
import { getUrl, wrap } from '../../utils/api-helpers';
import { urlRequest, setOptions } from '../../utils/secure-client';

const PREFIX_URL = getUrl('/_exclusive/stores/onboardings');

const interfaces = {
  getOnboardingStatus(query) {
    return this.get(urlRequest(PREFIX_URL, '', query));
  },
  setOnboardingStatus(query) {
    return this.post(`${PREFIX_URL}`, 'store', setOptions(query));
  },
};

const wrappedInterfaces = wrap(interfaces, 'xhrOnboardings');

export default wrappedInterfaces;
