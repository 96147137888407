/* eslint-disable no-undef */
import { getUrl, wrap } from '../../utils/api-helpers';
import { setOptions } from '../../utils/secure-client';
import { cleanUrlQuery } from '../../utils/url-helpers';

const URL = getUrl('/_exclusive/info/toggle-features');

const generateToggleAgregatePayload = listToggle => {
  const aggregate = {};
  listToggle.forEach(list => {
    aggregate[list] = {
      method: 'GET',
      path: `/_exclusive/info/toggle-features?feature=${list}`,
    };
  });

  return {
    aggregate,
  };
};

const interfaces = {
  /**
   * Get all Toggle Features
   *
   * @see https://blueprint.bukalapak.io/exclusive/misc.html#misc-toggle-features-retrieve-all-toggle-features
   */
  getAllToggleFeatures() {
    return this.get(`${URL}/collections`, 'store', setOptions());
  },

  /**
   * Get all list toggle feature for seller center or registered constant
   * in folder store/modules/toggle-features/list-toggle.json
   *
   * @param {Array} listToggle - constant string toggle features
   */
  getListToggle(listToggle) {
    const data = generateToggleAgregatePayload(listToggle);
    return this.post(`${getUrl()}/aggregate`, 'store', setOptions(data));
  },

  /**
   * Get toggle status
   *
   * @param {String} name registered toggle name (on mothership/palanca)
   * @see https://blueprint.bukalapak.io/exclusive/misc.html#misc-toggle-features-retrieve-status-of-a-toggle-feature
   */
  getToggleStatus(name) {
    const params = cleanUrlQuery({
      urlQuery: {
        feature: name,
      },
    });
    return this.get(`${URL}?${params}`, 'store', setOptions());
  },
};

const wrappedInterfaces = wrap(interfaces, 'xhrUsers');

export default wrappedInterfaces;
