<template>
  <span>
    <span class="u-display-inline-block u-txt--base u-pad-right--3 ut-title">{{ title }}</span>
    <bl-icon-av
      v-if="showIcon"
      class="c-tabs-vertical__icon u-position-absolute"
      name="ico_check_circle"
      color="crimson"
    />
  </span>
</template>

<script>
import BlIconAv from 'shared/javascripts/components/BlIconAv';

export default {
  name: 'FilterTabNav',
  components: {
    BlIconAv,
  },
  props: {
    showIcon: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.c-tabs-vertical__icon {
  width: 18px !important;
  height: 18px !important;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}
</style>
