<template>
  <bl-flex-container-mv class="u-bg--white" direction="column" align-content="flex-start">
    <bl-flex-item-av class="u-pad-bottom--4 u-txt--bold" grow="1">
      <bl-text-av size="14" weight="bold">Catatan Pelapak</bl-text-av>
    </bl-flex-item-av>
    <bl-flex-item-av
      :class="showMore ? '' : 'u-height--100 u-overflow--hidden'"
      class="u-txt--small store-notes u-mrgn-bottom--3"
      grow="1"
      v-html="store.term_and_condition"
    />
    <bl-flex-item-av v-if="!showMore" class="u-txt--center" grow="1">
      <div class="u-pointer" @click="showMore = true">
        <bl-text-av size="14" weight="bold" class="u-fg--red-brand">
          Tampilkan Lebih Banyak
        </bl-text-av>
      </div>
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageDescription',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    ...mapState('store', ['store']),
  },
  mounted() {
    const element = document.getElementsByClassName('store-notes')[0];
    const isNotesOverflown = element && element.scrollHeight > element.clientHeight;
    this.showMore = !isNotesOverflown;
  },
};
</script>
<style lang="scss" scope>
.spica {
  .store-notes {
    ul,
    ol {
      margin-left: 1.4em !important;
    }
  }
}
</style>
