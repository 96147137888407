/* eslint-disable no-undef */
import { getUrl, wrap } from '../../utils/api-helpers';
import { urlRequest, setOptions } from '../../utils/secure-client';

const PREFIX_URL = getUrl('/stores');

const interfaces = {
  getStores(query) {
    this.abortRequest();
    return this.get(urlRequest(PREFIX_URL, '', query));
  },
  getStoreById(id) {
    return this.get(`${PREFIX_URL}/${id}`, 'store', setOptions());
  },
  getBrand(id) {
    return this.get(`/official-brand-stores/${id}`, '', setOptions());
  },
  getStoreByUsername(username) {
    return this.get(`${PREFIX_URL}/${username}/profile`, 'public', setOptions());
  },
  getBrandBannerById(id) {
    return this.get(`/template-pages/users/${id}`, '', setOptions());
  },
  getBrandLabelsById(id) {
    return this.get(`/template-pages/users/${id}/labels`, '', setOptions());
  },
  getStoreLabels(id, query) {
    return this.get(urlRequest(`${PREFIX_URL}/${id}/labels`, '', query), 'store', setOptions());
  },
  getStoreProducts(id, query, abortRequest = true) {
    if (abortRequest) {
      this.abortRequest();
    }
    const isPreproduction = process.env.BUKALAPAK_HOSTNAME.includes('preproduction');
    const headers = isPreproduction ? { 'Bukalapak-Quicksearch': false } : {};
    return this.get(urlRequest(`${PREFIX_URL}/${id}/products`, '', query), '', setOptions({}, headers));
  },
  getStorePremiumVouchers(id) {
    return this.get(`${PREFIX_URL}/${id}/premium-vouchers`, 'store', setOptions());
  },
  /**
   * Get user's available courier
   * @param {string} id - User ID
   */
  getCouriers(id) {
    return this.get(`${PREFIX_URL}/${id}/shipments`, 'store', setOptions());
  },
  updateStoreStatus(id, payload) {
    return this.patch(`${PREFIX_URL}/${id}/status`, 'store', setOptions(payload));
  },
};

const wrappedInterfaces = wrap(interfaces, 'xhrStores');

export default wrappedInterfaces;
