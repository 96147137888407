/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_play_outlined: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8zM10 8l6 4-6 4z"/>',
  },
});
