/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_alert: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M12 22A10 10 0 1 0 2 12a10 10 0 0 0 10 10zM11 7h2s.47 4-.25 6h-1.5c-.81-2-.25-6-.25-6zm0 8h2v2h-2z" data-name="ico_ alert"/>',
  },
});
