/* eslint-disable no-undef */
import { getUrl, wrap } from '../../utils/api-helpers';
import { setOptions } from '../../utils/secure-client';

const PREFIX_URL = getUrl('/couriers');

const interfaces = {
  /**
   * Get all categories
   */
  getCouriers() {
    return this.get(PREFIX_URL, '', setOptions());
  },
};

const wrappedInterfaces = wrap(interfaces, 'xhrCouriers');

export default wrappedInterfaces;
