/* eslint-disable no-undef */
import { getUrl, wrap } from '../../utils/api-helpers';

const URL_STORE_PH = storeId => getUrl(`/stores/${storeId}/product-highlights`);

const interfaces = {
  getProductHighlights(storeId) {
    return this.get(URL_STORE_PH(storeId));
  },
};

const wrappedInterfaces = wrap(interfaces, 'xhrProductHighlights');

export default wrappedInterfaces;
