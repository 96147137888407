<template>
  <bl-flex-container-mv direction="column" class="u-bg--white" padding="16px" margin="12px 0 0 0">
    <bl-flex-item-av class="u-width-1" grow="1">
      <bl-button-group-mv>
        <bl-button-av @click="$emit('open-label-sheet')" class="spica-button u-txt--base" variation="naked">
          <bl-icon-av slot="icon" name="ico_list_view_minor" color="#2e2e2e" />
          <bl-icon-av
            v-if="filters.label_id"
            class="icon-check u-bg--white u-border-radius--50 u-position-absolute ut-actionbutton-icon-label"
            slot="icon"
            name="ico_check_circle"
            color="uranium"
            size="10"
          />
          <span :class="{ 'u-txt--bolder': filters.label_id }" class="u-txt--base">{{ currentLabel }} </span>
        </bl-button-av>
        <bl-button-av @click="$emit('open-filter-sheet')" class="spica-button u-txt--base" variation="naked">
          <bl-icon-av slot="icon" name="ico_filter_minor" color="#2e2e2e" />
          <bl-icon-av
            v-if="isFilterActive"
            class="icon-check u-bg--white u-border-radius--50 u-position-absolute ut-actionbutton-icon-filter"
            slot="icon"
            name="ico_check_circle"
            color="uranium"
            size="10"
          />
          <span :class="{ 'u-txt--bolder': isFilterActive }" class="u-txt--base">Filter</span>
        </bl-button-av>
        <bl-button-av @click="$emit('open-sort-sheet')" class="spica-button u-txt--base" variation="naked">
          <bl-icon-av slot="icon" name="ico_sort_minor" color="#2e2e2e" />
          <bl-icon-av
            v-if="chosenSort"
            class="icon-check u-bg--white u-border-radius--50 u-position-absolute ut-actionbutton-icon-sort"
            slot="icon"
            name="ico_check_circle"
            color="uranium"
            size="10"
          />
          <span class="u-txt--base u-txt--bolder">{{ chosenSort ? chosenSort.name : 'Urutkan' }}</span>
        </bl-button-av>
      </bl-button-group-mv>
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import { BlFlexContainerMv, BlButtonGroupMv, BlFlexItemAv, BlButtonAv } from '@bukalapak/bazaar-mweb';
import BlIconAv from 'shared/javascripts/components/BlIconAv';
import { mapState } from 'vuex';
import sortData from '../../data/sorts.json';

export default {
  name: 'MerchantPageActionButton',
  props: {
    isFlagship: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BlFlexContainerMv,
    BlButtonGroupMv,
    BlFlexItemAv,
    BlButtonAv,
    BlIconAv,
  },
  computed: {
    ...mapState('store', ['labels', 'filters']),
    currentLabel() {
      const currentLabel = this.labels.find(e => e.id === this.filters.label_id);
      return currentLabel ? currentLabel.name : 'Etalase';
    },
    chosenSort() {
      return sortData.find(sort => this.filters.sort === sort.value);
    },
    isFilterActive() {
      return (
        !!this.filters.couriers.length ||
        !!this.filters.price_range ||
        !!this.filters.condition ||
        !!this.filters.rating ||
        !!this.filters.free_shipping_provinces ||
        !!this.filters.deal ||
        !!this.filters.installment ||
        !!this.filters.wholesale
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.spica {
  &-button {
    height: auto;
    padding: 0;
    /deep/ .bl-button__text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      word-break: break-all;
      white-space: nowrap;
    }
    .icon-check {
      bottom: 0;
      right: -1px;
    }
  }
}
</style>
