/**
 * @module plugins/BlFlashMessage
 */

import BlFlashMessage, { BlFlashMessageEventBus } from './BlFlashMessage';

/**
 * BlFlashMessage Plugin
 *
 * @example
 * // Install the plugin
 * import BlFlashMessagePlugin from 'shared/components/plugins/BlFlashMessage';
 * Vue.use(BlFlashMessagePlugin);
 *
 * // Use BLCRemodal global component inside your template
 * // Make sure to name it accordingly
 * <template>
 *    <Flash />
 * </template>
 *
 * // Using the plugin inside component method / lifecycle
 * this.$BLFlash.show('text message', 'success')
 */
const BlFlashMessagePlugin = {
  install(Vue) {
    // attach as instance method
    Vue.prototype.$BLFlash = {
      show(text, state, action, callback = () => {}) {
        BlFlashMessageEventBus.$emit('show', text, state, action, callback);
      },
    };

    Vue.component('Flash', BlFlashMessage);
  },
};

export default BlFlashMessagePlugin;
