<template>
  <div class="u-pad--3 revamp-c-card--body">
    <div class="c-product-card-description">
      <a
        class="c-product-card__name js-tracker-product-link u-mrgn-bottom--1 c-product-card__ellipsis u-txt--break"
        rel="nofollow"
        :href="productUrl"
        :title="product.name"
      >
        {{ product.name }}
      </a>
      <div class="u-txt--ellipsis u-mrgn-bottom--1">
        <template v-if="hasDeal">
          <span class="u-fg--red-brand u-txt--bold">
            {{ product.deal.discount_price | simpleMoney }}
          </span>
          <span class="u-fg--ash-dark u-txt--small u-txt--strikethrough">
            {{ product.deal.original_price | simpleMoney }}
          </span>
        </template>
        <span v-else class="u-display-block u-txt--bold c-product-price-normal">
          {{ product.price | simpleMoney }}
        </span>
      </div>
      <div class="c-product-rating u-mrgn-bottom--1 u-overflow--hidden">
        <template v-if="Object.keys(product.rating).length">
          <bl-rating-mv :value="ratingPercentage" :max-ratings="5" />
          <a class="c-product-rating__count u-fg--ash-dark u-txt--small" rel="nofollow" :href="productReviewPath">
            ({{ productReviewCount }} Ulasan)
          </a>
        </template>
      </div>
      <bl-button-av
        v-if="!isOwnProduct"
        variation="primary"
        class="u-mrgn-top--1 js-add-to-cart-btn js-track-atc-reco"
        fullwidth
        size="small"
        :disabled="isLoading"
        @click="$emit('add-to-cart')"
      >
        <span class="u-txt--bold">Beli</span>
      </bl-button-av>
    </div>
  </div>
</template>

<script>
import { simpleMoney } from 'shared/javascripts/utils/number-helper';
import { BlRatingMv } from '@bukalapak/bazaar-dweb';
import { BlButtonAv } from '@bukalapak/bazaar-mweb';

export default {
  name: 'ProductCardBody',
  components: {
    BlRatingMv,
    BlButtonAv,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isOwnProduct: {
      type: Boolean,
      default: false,
    },
    keywords: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
      default: '',
    },
  },
  computed: {
    productReviewCount() {
      return simpleMoney(this.product.rating.user_count, '').replace('rb', 'K');
    },
    ratingPercentage() {
      const averageRate = this.product.rating.average_rate ? this.product.rating.average_rate : 0;
      return (averageRate / 5) * 100;
    },
    productReviewPath() {
      const a = document.createElement('a');
      a.setAttribute('href', this.product.url || '');
      return `/reviews${a.pathname.replace('/p/', '/')}`;
    },
    productUrl() {
      return this.query ? `${this.product.url}?${this.query}` : this.product.url;
    },
    hasDeal() {
      const { deal } = this.product;
      if (deal && deal.applied_date && deal.expired_date) {
        const validAppliedDate = Date.parse(deal.applied_date) <= Date.now();
        const validExpiredDate = Date.parse(deal.expired_date) > Date.now();
        return validAppliedDate && validExpiredDate;
      }
      return false;
    },
  },
};
</script>

<style src="./RevampProductCardBody.style.scss" lang="scss" scoped></style>
