/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_notification_off: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M19 14.36L8.89 4.25A5.2 5.2 0 0 1 10 4a2 2 0 0 1 4 0 5.49 5.49 0 0 1 5 5.5zM12 22a3 3 0 0 0 3-2.5H9.05A3 3 0 0 0 12 22zm9.16-3L5 2.84 3.59 4.25l2.29 2.29A5.36 5.36 0 0 0 5 9.5V16H4a1 1 0 0 0 0 2h13.34l2.41 2.41z"/>',
  },
});
