<template>
  <div>
    <merchant-page-description v-if="store.description" class="u-pad--6 u-pad-v--4 u-mrgn-bottom--5" />
    <merchant-page-reputation v-if="store.level.name || isPremiumUser" class="u-pad--6 u-pad-v--4 u-mrgn-bottom--5" />
    <merchant-page-last-order-schedule
      v-if="lastOrderScheduleTime"
      :time="lastOrderScheduleTime"
      class="u-pad--6 u-pad-v--4 u-mrgn-bottom--5"
    />
    <merchant-page-statistics class="u-pad--6 u-pad-v--4 u-mrgn-bottom--5" />
    <merchant-page-feedbacks v-if="feedbacks.meta.total" class="u-pad--6 u-pad-v--4 u-mrgn-bottom--5" />
    <merchant-page-notes v-if="store.term_and_condition" class="u-pad--6 u-pad-v--4 u-mrgn-bottom--5" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { humanizeTimestamp } from 'shared/javascripts/utils/date-helpers';
import MerchantPageDescription from './components/MerchantPageDescription';
import MerchantPageLastOrderSchedule from './components/MerchantPageLastOrderSchedule';
import MerchantPageReputation from './components/MerchantPageReputation';
import MerchantPageStatistics from './components/MerchantPageStatistics';
import MerchantPageFeedbacks from './components/MerchantPageFeedbacks';
import MerchantPageNotes from './components/MerchantPageNotes';
import DayName from './data/day-name.json';

export default {
  name: 'MerchantPageDetailInfo',
  components: {
    MerchantPageDescription,
    MerchantPageLastOrderSchedule,
    MerchantPageReputation,
    MerchantPageStatistics,
    MerchantPageFeedbacks,
    MerchantPageNotes,
  },
  computed: {
    ...mapState('store', ['store', 'feedbacks']),
    ...mapState('toggleFeatures', ['toggle']),
    isSuperSellerActive() {
      return this.toggle['premium/super_seller'];
    },
    isPremiumUser() {
      return this.store.premium_level !== 'none' && !this.isSuperSellerActive;
    },
    lastOrderScheduleTime() {
      if (this.store.last_order_schedule && Object.keys(this.store.last_order_schedule).length) {
        const todayDate = new Date();
        const todayDayIdx = todayDate.getDay();
        const todayDayName = DayName[todayDayIdx];
        if (Object.keys(this.store.last_order_schedule).indexOf(todayDayName) > -1) {
          const todayDateHour = humanizeTimestamp(todayDate, '%hh%:%mi%');
          const todayDateStr = '01/01/2019';
          const lastOrderHour = this.store.last_order_schedule[todayDayName];
          const couldStillOrder =
            Date.parse(`${todayDateStr} ${todayDateHour}:00`) < Date.parse(`${todayDateStr} ${lastOrderHour}:00`);
          return couldStillOrder ? lastOrderHour : '';
        }
      }
      return '';
    },
  },
};
</script>
