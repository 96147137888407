/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_check_circle: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4.77 7.64l-5 6a1 1 0 0 1-.72.36H11a1 1 0 0 1-.71-.29l-3-3a1 1 0 1 1 1.42-1.42l2.22 2.23 4.3-5.16a1 1 0 0 1 1.54 1.28z"/>',
  },
});
