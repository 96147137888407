/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_heart_outline: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.4 4.63a5.42 5.42 0 0 0-7.75 0 5.35 5.35 0 0 0-.65.79 5.35 5.35 0 0 0-.65-.79 5.42 5.42 0 0 0-7.75 0 5.63 5.63 0 0 0 0 7.86L12 21l8.4-8.51a5.63 5.63 0 0 0 0-7.86zm-1.56 6.59L12 18.15l-6.84-6.93A3.8 3.8 0 0 1 5 6a3.4 3.4 0 0 1 2.48-1 4.87 4.87 0 0 1 4.1 2.68.49.49 0 0 0 .84 0A4.87 4.87 0 0 1 16.52 5 3.4 3.4 0 0 1 19 6a3.8 3.8 0 0 1-.16 5.22z"/>',
  },
});
