/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_flag: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21 6.5v7a1.5 1.5 0 0 1-1.5 1.5h-4.72a1 1 0 0 1-1-.76L13.5 13H7.63L6.52 3h6.2a1 1 0 0 1 1 .76L14 5h5.5A1.5 1.5 0 0 1 21 6.5zM3.89 2A1 1 0 0 0 3 3.11l2 18A1 1 0 0 0 6 22h.11A1 1 0 0 0 7 20.89l-2-18A1 1 0 0 0 3.89 2z"/>',
  },
});
