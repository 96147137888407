/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_promotion: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M22 3.5v15a.5.5 0 0 1-.64.48l-9-2.7a.49.49 0 0 1-.36-.48V6.2a.49.49 0 0 1 .36-.48l9-2.7a.5.5 0 0 1 .64.48zM9.5 6H7a5 5 0 0 0-.26 10l.88 3.52A2 2 0 0 0 9.56 21H10V6.5a.5.5 0 0 0-.5-.5z"/>',
  },
});
