/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_chevron_right_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M13.71 10.71l-5 5a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42l4.3-4.29-4.3-4.29a1 1 0 1 1 1.42-1.42l5 5a1 1 0 0 1 0 1.42z"/>',
  },
});
