<template>
  <bl-flex-container-mv class="u-bg--white" direction="column" align-content="flex-start">
    <bl-flex-item-av class="u-pad-bottom--4" grow="1">
      <bl-text-av size="14" weight="bold">Reputasi</bl-text-av>
    </bl-flex-item-av>
    <bl-flex-item-av v-if="store.brand_seller">
      <img class="u-position-relative ut-bukamall-logo" :src="BUKAMALL_LOGO_URL" style="height: 30px; top: -3px;" />
      <bl-text-av size="12" class="u-mrgn-bottom--2">Jaminan 100% Original</bl-text-av>
      <bl-text-av size="12"
        >Lapak resmi brand original yang menjual berbagai macam barang yang keasliannya dijamin 100% oleh
        Bukalapak</bl-text-av
      >
    </bl-flex-item-av>
    <bl-flex-item-av v-else-if="store.level.name" grow="1">
      <div
        class="ut-reputation-seller-level c-seller-badge c-seller-badge--small c-seller-badge--hollow u-mrgn-right--2"
      >
        <img
          class="u-valign-bottom"
          :src="store.level.image_url"
          :alt="`Seller Badge ${store.level.name}`"
          title="Seller"
        />
      </div>
      <bl-text-av size="12" class="u-display-inline u-valign-middle">{{ store.level.name }}</bl-text-av>
    </bl-flex-item-av>
    <bl-flex-item-av v-if="isPremiumUser" grow="1" class="u-mrgn-top--2">
      <span class="c-seller-badge u-mrgn-right--2 c-seller-badge--premium c-seller-badge--small" />
      <bl-text-av size="12" class="u-display-inline u-valign-middle">Pelapak Premium</bl-text-av>
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import BukamallMixin from 'shared/javascripts/mixins/bukamall';
import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageReputation',
  mixins: [BukamallMixin],
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
  },
  computed: {
    ...mapState('store', ['store']),
    ...mapState('toggleFeatures', ['toggle']),
    isSuperSellerActive() {
      return this.toggle['premium/super_seller'];
    },
    isPremiumUser() {
      return this.store.premium_level !== 'none' && !this.isSuperSellerActive;
    },
  },
};
</script>
