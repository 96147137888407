<template>
  <div class="u-bg--white">
    <div class="u-pad-bottom--4">
      <bl-text-av size="14" weight="bold" class="u-display-inline">Feedback</bl-text-av>
      <bl-text-av size="14" class="u-display-inline">({{ feedbacks.meta.total }})</bl-text-av>
    </div>
    <div class="u-mrgn-bottom--3 ut-feedbacks-item" v-for="feedback in feedbacks.data" :key="feedback.id">
      <span
        :class="feedback.feedback.positive ? 'u-fg--green c-icon--thumb-up' : 'u-fg--red-brand c-icon--thumb-down'"
        class="c-icon u-pad-right--2"
      />
      <div class="u-inline-block u-valign-top u-width-10of12 u-txt--base">
        <div class="u-mrgn-bottom--1">
          <bl-text-av size="12"><span v-html="feedback.feedback.content"/></bl-text-av>
        </div>
        <bl-text-av size="12" class="u-fg--ash-dark">
          Oleh <a class="u-fg--ash-dark" :href="`/u/${feedback.sender.username}`">{{ feedback.sender.username }}</a>
        </bl-text-av>
      </div>
    </div>
    <div v-if="feedbacks.meta && feedbacks.meta.total > 3" class="u-txt--center ut-feedbacks-see-more" grow="1">
      <bl-text-av size="14" class="u-fg--red-brand" weight="bold" :href="`/u/${store.owner.username}/feedback`">
        Tampilkan Lebih Banyak
      </bl-text-av>
    </div>
  </div>
</template>

<script>
import { BlTextAv } from '@bukalapak/bazaar-mweb';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageFeedbacks',
  components: {
    BlTextAv,
  },
  computed: {
    ...mapState('store', ['store', 'feedbacks']),
  },
};
</script>
