<template>
  <revamp-product-card-mobile
    @add-to-cart="addToCart"
    :product="product"
    :is-loading="isLoading"
    :is-own-product="isCurrentUserStore"
    :query="queryProductCard"
  />
</template>

<script>
import RevampProductCardMobile from 'shared/javascripts/components/RevampProductCardMobile';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { trackAddToCart } from 'shared/javascripts/utils/tracker';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageProductCard',
  components: {
    RevampProductCardMobile,
  },
  mixins: [StoreStateMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addToCartProductId: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('store', ['feedbacks']),
    userIdentity() {
      return global.USER.identity;
    },
    queryProductCard() {
      const productOwner = this.store.brand_seller ? 'brand_seller' : 'normal_seller';
      const from = this.store.brand_seller ? 'brand-page' : 'product_owner';
      return `from=${from}&product_owner=${productOwner}`;
    },
  },
  methods: {
    async addToCart() {
      this.isLoading = true;
      let isError = false;
      const query = {
        quantity: this.product.min_quantity,
        product_id: this.product.id.toString(),
      };
      const payloadAtc = { query, identity: this.userIdentity };
      try {
        await this.$store.dispatch('store/ADD_TO_CART', payloadAtc);
        this.$BLFlash.show('Barang berhasil dimasukan ke keranjang', 'success');
      } catch (e) {
        isError = true;
        this.$root.notifyError(e, 'addToCart', payloadAtc, this.$options.name);
        if (e.errors && e.errors.length) {
          this.$BLFlash.show(e.errors[0].message, 'error');
        } else {
          this.$BLFlash.show('Terjadi kesalahan pada server', 'error');
        }
      }
      this.isLoading = false;
      if (!isError) {
        this.sendTracker();
      }
    },
    async sendTracker() {
      const payload = {
        seller_ids: [this.store.id],
        identity: this.userIdentity,
      };
      try {
        const { data: cart } = await this.$store.dispatch('store/RETRIEVE_CART', payload);
        const cartItem = cart.items.find(e => e.stuff.product_id === this.product.id);
        if (cartItem.id) {
          trackAddToCart({
            cart,
            cartItem,
            product: this.product,
            merchantId: this.store.id,
            totalFeedback: this.feedbacks.meta.total,
            referrerEvent: 'merchant-page/product-list',
          });
        }
      } catch (e) {
        this.$root.notifyError(e, 'sendAddToCartTracker', payload, this.$options.name);
      }
    },
  },
};
</script>
