/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_product: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20 10.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 19.5v-9a.5.5 0 0 1 .5-.5H10v5h4v-5h5.5a.5.5 0 0 1 .5.5zM13.57 8H20l-1.86-3.72a.51.51 0 0 0-.45-.28h-5zm-2.28-4h-5a.51.51 0 0 0-.45.28L4 8h6.43z"/>',
  },
});
