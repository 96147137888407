<template>
  <bl-flex-container-mv
    justifyContent="space-between"
    alignItems="center"
    class="u-margin-top--0 u-bg--white u-border-top--1--sand-dark u-overflow--hidden"
    padding="12px 0px"
  >
    <bl-flex-item-av
      v-if="showFollowChatButton"
      grow="1"
      class="u-align-center u-border-right--1--sand-dark u-border-radius--0"
    >
      <bl-button-av :href="messageUrl" class="spica-button" variation="naked">
        <bl-icon-av slot="icon" class="u-mrgn-right--1" name="ico_chat" color="active-lighter" />
        <bl-text-av size="14" class="u-fg--red-brand" weight="bold">
          Chat
        </bl-text-av>
      </bl-button-av>
    </bl-flex-item-av>
    <bl-flex-item-av grow="1" class="u-align-center">
      <bl-button-av @click="$emit('open-detail-info')" class="spica-button u-chatfollow-info-button" variation="naked">
        <bl-icon-av slot="icon" class="u-valign-middle" name="ico_info_minor" color="active-lighter" />
        <bl-text-av size="14" class="u-fg--red-brand" weight="bold">
          Info Lapak
        </bl-text-av>
      </bl-button-av>
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv, BlButtonAv, BlTextAv } from '@bukalapak/bazaar-mweb';
import BlIconAv from 'shared/javascripts/components/BlIconAv';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageChatFollow',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlIconAv,
    BlButtonAv,
    BlTextAv,
  },
  mixins: [StoreStateMixin],
  props: {
    showFollowChatButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('store', ['store']),
    ...mapState('chat', ['onlineStatus']),
    messageUrl() {
      return `/messages/${this.store.id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.spica {
  &-bl-badge__circle--mweb {
    width: 8px;
    height: 8px;
    padding: 4px;
  }
  &-button {
    height: 24px;
  }
}
</style>
