/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_lock: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M19 10h-1V7a5 5 0 0 0-5-5h-2a5 5 0 0 0-5 5v3H5a1 1 0 0 0-1 1v9.5A1.5 1.5 0 0 0 5.5 22h13a1.5 1.5 0 0 0 1.5-1.5V11a1 1 0 0 0-1-1zM9 7a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v3H9zm4 10a1 1 0 0 1-2 0v-2a1 1 0 0 1 2 0z"/>',
  },
});
