/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_shop: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M22 12.36L20.45 7H3.55L2 12.36a.5.5 0 0 0 .5.64H4v6.5a.5.5 0 0 0 .5.5H14v-7h4v7h1.5a.5.5 0 0 0 .5-.5V13h1.5a.5.5 0 0 0 .5-.64zM12 18H6v-5h6zm8-12.5H4v-2a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5z"/>',
  },
});
