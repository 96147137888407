/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_tag_label: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.5 2h-7.92a1.52 1.52 0 0 0-1 .39L2.5 10.72a1.49 1.49 0 0 0-.16 2.06l7.09 8.67a1.5 1.5 0 0 0 2.17.16l9.91-9.16a1.52 1.52 0 0 0 .49-1.11V3.5A1.5 1.5 0 0 0 20.5 2zM17 9a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/>',
  },
});
