<template>
  <div>
    <bl-flex-container-mv direction="row" class="u-mrgn-bottom--2 spica-share-lapak">
      <bl-flex-item-av
        v-for="link in getShareLinks"
        :key="link.name"
        basis="0"
        shrink="1"
        grow="1"
        class="u-txt--center"
      >
        <bl-button-av @click="handleShareLink(link)" variation="outline">
          <div slot="icon">
            <span :class="`c-icon--${link.icon} u-fg-external--${link.color}`" class="c-icon" />
          </div>
        </bl-button-av>
      </bl-flex-item-av>
    </bl-flex-container-mv>
  </div>
</template>

<script>
import { copyTextToClipboard } from 'shared/javascripts/utils/clipboard-helpers';
import { BlButtonAv, BlFlexItemAv, BlFlexContainerMv } from '@bukalapak/bazaar-mweb';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageShare',
  components: {
    BlButtonAv,
    BlFlexItemAv,
    BlFlexContainerMv,
  },
  computed: {
    ...mapState('store', ['store']),
    getShareLinks() {
      return {
        facebook: {
          name: 'Facebook',
          utm_source: 'facebook',
          icon: 'facebook',
          color: 'facebook',
          url: 'https://www.facebook.com/sharer/sharer.php?u=',
        },
        twitter: {
          name: 'Twitter',
          utm_source: 'twitter',
          icon: 'twitter',
          color: 'twitter',
          url: `https://www.twitter.com/share?text=${this.store.name}&url=`,
        },
        pinterest: {
          name: 'Pinterest',
          utm_source: 'pinterest',
          icon: 'pinterest',
          color: 'pinterest',
          url: 'https://www.pinterest.com/pin/create/button/?url=',
        },
        line: {
          name: 'Line',
          utm_source: 'line.me',
          icon: 'line-full',
          color: 'line',
          url: 'https://social-plugins.line.me/lineit/share?url=',
        },
        copy: {
          name: 'Copy',
          icon: 'copy',
          color: '',
        },
      };
    },
  },
  methods: {
    handleShareLink(item) {
      if (item.name !== 'Copy') {
        const utmQuery = {
          utm_source: item.utm_source,
          utm_medium: 'bukalapakpageshare',
          utm_campaign: 'lapakshare',
        };
        const query = `${this.store.url}?${global.queryString.stringify(utmQuery)}`;
        const url = `${item.url}${encodeURIComponent(query)}`;
        try {
          const win = window.open(
            url,
            '',
            'menubar=no, toolbar=no, resizable=yes, scrollbars=yes, height=600, width=600',
          );
          win.focus();
        } catch (e) {
          console.error(e);
        }
      } else {
        this.copyStoreLink();
      }
    },
    copyStoreLink() {
      if (copyTextToClipboard(this.store.url)) {
        this.$BLFlash.show('URL lapak berhasil disalin', 'success');
      } else {
        this.$BLFlash.show('URL lapak gagal disalin', 'error');
      }
    },
  },
};
</script>
<style>
.spica-share-lapak {
  min-height: 100px;
}
</style>
