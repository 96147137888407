<template>
  <bl-flex-container-mv class="u-bg--white" direction="column" align-content="flex-start" padding="12px">
    <bl-flex-item-av class="u-mrgn-bottom--1" grow="1">
      <bl-text-av size="12" class="u-fg--ash-dark">Pesan Sebelum</bl-text-av>
    </bl-flex-item-av>
    <bl-flex-item-av class="u-mrgn-bottom--1 u-txt--bold" grow="1">
      <bl-text-av size="14" weight="bold">{{ time }} WIB</bl-text-av>
    </bl-flex-item-av>
    <bl-flex-item-av class="u-mrgn-bottom--1" grow="1">
      <bl-text-av size="12" class="u-fg--charcoal">Agar barang dikirim hari ini</bl-text-av>
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb';

export default {
  name: 'MerchantPageLastOrderSchedule',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
  },
  props: {
    time: {
      type: String,
      default: '',
    },
  },
};
</script>
