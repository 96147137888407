import ChatAPI from 'shared/javascripts/api/chat';

export const initialState = () => ({
  lastSeen: '',
  onlineStatus: false,
});

const getters = {};

export const mutations = {
  updateLastSeen: (state, lastSeen) => {
    state.lastSeen = lastSeen;
  },
  updateOnlineStatus: (state, isOnline) => {
    state.onlineStatus = isOnline;
  },
};

export const actions = {
  RETRIEVE_LAST_SEEN: async ({ commit }, partnerId) => {
    const response = await ChatAPI.retrieveLastSeen(partnerId);
    commit('updateLastSeen', response.data.last_seen || '');
    return response;
  },
  RETRIEVE_ONLINE_STATUS: async ({ commit }, userId) => {
    const { data } = await ChatAPI.retrieveOnlineStatus([userId]);
    const isOnline = Array.isArray(data.onlines) && data.onlines.includes(userId.toString());
    commit('updateOnlineStatus', isOnline);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
