/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_group: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M16.5 12A2.5 2.5 0 1 0 14 9.5a2.49 2.49 0 0 0 2.5 2.5zM9 11a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25a3.94 3.94 0 0 1 2.37-3.47A12.28 12.28 0 0 0 9 13z"/>',
  },
});
