/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_back_android: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20 12a1 1 0 0 1-1 1H7.41l4.3 4.29a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0l-6-6a1 1 0 0 1 0-1.42l6-6a1 1 0 1 1 1.42 1.42L7.41 11H19a1 1 0 0 1 1 1z"/>',
  },
});
