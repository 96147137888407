/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_star: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M12.83 2.53L15.39 8l5.83.9a.92.92 0 0 1 .52 1.55l-4.25 4.36 1 6.11a.91.91 0 0 1-1.34.95L12 19l-5.14 2.88a.91.91 0 0 1-1.34-.95l1-6.11-4.26-4.36a.92.92 0 0 1 .52-1.55L8.61 8l2.56-5.48a.91.91 0 0 1 1.66.01z"/>',
  },
});
