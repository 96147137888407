/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_ui_tick: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.78 6.62l-9.5 12a1 1 0 0 1-.7.38h-.08a1 1 0 0 1-.67-.26l-5.5-5a1 1 0 1 1 1.34-1.48l4.71 4.28 8.84-11.16a1 1 0 1 1 1.56 1.24z"/>',
  },
});
