/* eslint-disable no-undef */
import { getUrl, wrap } from '../../utils/api-helpers';
import { setOptions, urlRequest } from '../../utils/secure-client';

const PREFIX_URL = getUrl('/carts');

const interfaces = {
  retrieveCart(query) {
    return this.get(urlRequest(PREFIX_URL, '', query));
  },
  addToCart(identity, query) {
    return this.post(`${PREFIX_URL}/items?identity=${identity}`, '', setOptions(query));
  },
};

const wrappedInterfaces = wrap(interfaces, 'xhrCarts');

export default wrappedInterfaces;
