/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_more_horizontal: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M19 14a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm-5-2a2 2 0 1 0-2 2 2 2 0 0 0 2-2zm-7 0a2 2 0 1 0-2 2 2 2 0 0 0 2-2z"/>',
  },
});
