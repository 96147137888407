/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_time_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M10 2a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm2.71 10.71a1 1 0 0 1-1.42 0l-1.85-1.86A1.5 1.5 0 0 1 9 9.79V7a1 1 0 0 1 2 0v2.59l1.71 1.7a1 1 0 0 1 0 1.42z"/>',
  },
});
