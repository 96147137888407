<template>
  <div class="spica__illustration u-position-absolute u-txt-align--center u-width-1">
    <template v-if="isStoreInactive">
      <div class="ut-pageillustration-freeze" v-if="isStoreFreezed">
        <div class="u-mrgn-bottom--3 u-2of4 u-mrgn-auto">
          <img :src="getAssetUrl('images/tutup_dibekukan-mdpi.png')" />
        </div>
        <div class="u-mrgn-top--4 u-txt--base u-txt--bold">Lapak Dibekukan</div>
        <div v-if="isCurrentUserStore" class="u-pad-h--4">Akunmu dibekukan oleh tim Policy Bukalapak.</div>
        <div v-else class="u-pad-h--4">Lapak dibekukan oleh tim Policy Bukalapak.</div>
      </div>
      <div class="ut-pageillustration-close" v-else>
        <div class="u-mrgn-bottom--3 u-2of4 u-mrgn-auto">
          <img :src="getAssetUrl('images/tutup_lapak-mdpi.png')" />
        </div>
        <template v-if="isCurrentUserStore">
          <div class="u-mrgn-top--4 u-txt--base u-txt--bold">Kamu Sedang Menutup Lapakmu</div>
          <div class="u-mrgn-bottom--4">Lapak tutup hingga {{ storeOpenDate }}.</div>
          <bl-button-av :href="sellerCenterSettingsUrl" variation="primary">
            Buka Lagi Lapakmu
          </bl-button-av>
        </template>
        <template v-else>
          <div class="u-mrgn-top--4 u-txt--base u-txt--bold">Kami Sedang Tutup 😄</div>
          <div class="u-mrgn-bottom--4 u-pad-h--10">
            Terima kasih sudah berkunjung. Kamu bisa kembali berbelanja pada {{ storeOpenDate }}. Sampai ketemu!
          </div>
          <bl-button-av href="/" variation="primary">
            Kembali ke Home
          </bl-button-av>
        </template>
      </div>
    </template>
    <div v-else-if="!isProductsLoading && !products.length" class="ut-pageillustration-not-found">
      <div class="u-mrgn-bottom--3 u-2of4 u-mrgn-auto">
        <img
          :src="
            filters.keywords || isFilterActive
              ? getAssetUrl('images/ill_barang_not_found.png')
              : getAssetUrl('images/ill_no_barang.png')
          "
        />
      </div>
      <div class="u-mrgn-bottom--2 u-txt-align--center">
        {{ filters.keywords || isFilterActive ? 'Barang tidak ditemukan' : 'Tidak ada barang' }}
      </div>
    </div>
  </div>
</template>

<script>
import { humanizeTimestamp } from 'shared/javascripts/utils/date-helpers';
import AssetsMixin from 'shared/javascripts/mixins/assets';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { BlButtonAv } from '@bukalapak/bazaar-mweb';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'MerchantPageIllustration',
  components: {
    BlButtonAv,
  },
  mixins: [AssetsMixin, StoreStateMixin],
  computed: {
    ...mapState('store', ['store', 'products', 'filters', 'isProductsLoading']),
    ...mapGetters('store', ['isStoreFreezed', 'isStoreInactive']),
    isFilterActive() {
      return (
        !!this.filters.couriers.length ||
        !!this.filters.price_range ||
        !!this.filters.condition ||
        !!this.filters.rating ||
        !!this.filters.free_shipping_provinces ||
        !!this.filters.deal ||
        !!this.filters.installment ||
        !!this.filters.wholesale
      );
    },
    storeOpenDate() {
      return humanizeTimestamp(this.store.closing.reopen_date, '%dd%/%mm%/%yy%');
    },
    sellerCenterSettingsUrl() {
      return `/users/${this.store.id}/close_store`;
    },
  },
};
</script>
<style lang="scss" scoped>
.spica {
  &__illustration {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}
</style>
