/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_chevron_left_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M12.71 14.29a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0l-5-5a1 1 0 0 1 0-1.42l5-5a1 1 0 1 1 1.42 1.42L8.41 10z"/>',
  },
});
