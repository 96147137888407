/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_polling: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M5 4.5v15a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-15a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zM20 10H7.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5H20a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1zm-4 6H7.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5H16a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1zM12 4H7.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5H12a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"/>',
  },
});
