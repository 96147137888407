/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_cross_circle_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M10 2a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm3.71 10.29a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0L10 11.41l-2.29 2.3a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42L8.59 10l-2.3-2.29a1 1 0 0 1 1.42-1.42L10 8.59l2.29-2.3a1 1 0 1 1 1.42 1.42L11.41 10z"/>',
  },
});
