<template>
  <bl-flex-container-mv
    v-if="vouchers.length"
    justify-content="flex-start"
    class="u-bg--white"
    padding="16px 18px"
    margin="12px 0 0 0"
  >
    <bl-flex-item-av grow="1">
      <div @click="$emit('open-voucher-modal')" class="ut-page-vouchers">
        <span class="c-dualtone-icon c-dualtone-icon--medium c-dualtone-icon--deals u-mrgn-right--3" />
        Ada {{ vouchers.length }} voucher lapak untukmu!
        <span class="u-float-right c-m-icon u-fg--ash c-icon--medium c-m-icon--navigate-next" />
      </div>
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageVoucher',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
  },
  computed: {
    ...mapState('store', ['vouchers']),
  },
};
</script>
