/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_login: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21 3v18a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3a1 1 0 0 1 2 0v2h11V4H8v2a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1zM10.29 15.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4-4a1 1 0 0 0 0-1.42l-4-4a1 1 0 1 0-1.42 1.42l2.3 2.29H4a1 1 0 0 0 0 2h8.59z"/>',
  },
});
