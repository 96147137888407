/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_cash: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.5 4h-17A1.5 1.5 0 0 0 2 5.5v13A1.5 1.5 0 0 0 3.5 20h17a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 20.5 4zm-3.36 14H6.86A4 4 0 0 0 4 15.14V8.86A4 4 0 0 0 6.86 6h10.28A4 4 0 0 0 20 8.86v6.28A4 4 0 0 0 17.14 18zM15 12a3 3 0 1 1-3-3 3 3 0 0 1 3 3z"/>',
  },
});
