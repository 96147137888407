<template>
  <div data-v-6978efe6="" style="padding: 16px 12px;">
    <a :href="homeUrl" class="u-mrgn-bottom--6 u-fg--black u-display-block ut-menu-home">
      <bl-icon-av name="ico_home" color="ui-darker" class="u-mrgn-right--2 u-valign-top" />
      Kembali ke home
    </a>
    <a :href="cartsUrl" class="u-mrgn-bottom--6 u-fg--black u-display-block ut-menu-cart">
      <bl-icon-av name="ico_cart" color="ui-darker" class="u-mrgn-right--2 u-valign-top" />
      Keranjang belanja saya
    </a>
    <div @click="$emit('open-detail-info')" class="u-mrgn-bottom--6 u-fg--black ut-menu-info">
      <bl-icon-av name="ico_info" color="ui-darker" class="u-mrgn-right--2 u-valign-top" />
      Informasi pelapak
    </div>
    <div @click="$emit('open-share-lapak')" class="u-mrgn-bottom--6 u-fg--black ut-menu-share">
      <bl-icon-av name="ico_share_android" color="ui-darker" class="u-mrgn-right--2 u-valign-top" />
      Bagikan lapak ini
    </div>
    <a :href="reportUrl" class="u-mrgn-bottom--2 u-display-block u-fg--black ut-menu-report">
      <bl-icon-av name="ico_flag" color="ui-darker" class="u-mrgn-right--2 u-valign-top" />
      Laporkan
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BlIconAv from 'shared/javascripts/components/BlIconAv';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';

export default {
  name: 'MerchantPageMenu',
  components: {
    BlIconAv,
  },
  mixins: [StoreStateMixin],
  computed: {
    ...mapState('store', ['store']),
    homeUrl() {
      const blUrl = `${process.env.BUKALAPAK_URL}`;
      return blUrl;
    },
    cartsUrl() {
      return `${this.homeUrl}/cart/carts`;
    },
    reportUrl() {
      return `${this.homeUrl}/users/${this.store.id}/report?comeback=${encodeURIComponent(
        document.URL,
      )}&comeback_action=show`;
    },
  },
};
</script>
