<template>
  <div>
    <transition name="slide-up-fade">
      <div v-if="show" :class="stateClass">
        <bl-flex-container-mv direction="row">
          <bl-flex-item-av grow="1" basis="0" class="u-mrgn-right--1">
            <bl-text-av class="bl-notification__text">
              {{ text }}
            </bl-text-av>
          </bl-flex-item-av>
          <bl-flex-item-av v-if="action">
            <bl-button-av size="small" variation="outline-white" @click.native="$emit('click')">
              {{ action }}
            </bl-button-av>
          </bl-flex-item-av>
        </bl-flex-container-mv>
      </div>
    </transition>
  </div>
</template>

<script>
import { BlTextAv, BlButtonAv, BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';

export default {
  name: 'BlNotificationMv',
  components: {
    BlTextAv,
    BlButtonAv,
    BlFlexContainerMv,
    BlFlexItemAv,
  },
  props: {
    /**
     * A custom text of notification.
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * A state variation to give additional meaning. `success, error, default`
     */
    state: {
      type: String,
      default: 'default',
      validator(value) {
        const sizing = ['success', 'error', 'default'];
        return sizing.indexOf(value) !== -1;
      },
    },
    /**
     * Toggle show/hide notification.
     */
    show: {
      type: Boolean,
      default: false,
    },

    action: {
      type: String,
      default: '',
    },
  },
  computed: {
    stateClass() {
      const baseClass = 'bl-notification';
      const array = [baseClass];
      array.push(`${baseClass}--${this.state}`);
      return array;
    },
  },
};
</script>
<style src="./BlNotificationMv.style.scss" lang="scss"></style>
