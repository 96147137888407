/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_filter_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M18 5.5v1a.5.5 0 0 1-.5.5H11V5h6.5a.5.5 0 0 1 .5.5zM10 6a3 3 0 0 1-5.82 1H2.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1.68A3 3 0 0 1 10 6zM8 6a1 1 0 1 0-1 1 1 1 0 0 0 1-1zm10 7.5v1a.5.5 0 0 1-.5.5h-1.68a3 3 0 1 1 0-2h1.68a.5.5 0 0 1 .5.5zm-4 .5a1 1 0 1 0-1 1 1 1 0 0 0 1-1zm-12-.5v1a.5.5 0 0 0 .5.5H9v-2H2.5a.5.5 0 0 0-.5.5z"/>',
  },
});
