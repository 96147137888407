const color = require('@bukalapak/bazaar-token');

const newColor = '#5C6066';

const {
  colorChromium,
  colorChromiumDark,
  colorChromiumDarker,
  colorChromiumLight,
  colorChromiumLighter,
  colorCrimson,
  colorCrimsonDark,
  colorCrimsonDarker,
  colorCrimsonLight,
  colorCrimsonLighter,
  colorInk,
  colorInkDark,
  colorInkLight,
  colorInkLighter,
  colorSodium,
  colorSodiumDark,
  colorSodiumDarker,
  colorSodiumLight,
  colorSodiumLighter,
  colorUi,
  colorUiDark,
  colorUiDarker,
  colorUiLight,
  colorUiWhite,
  colorUranium,
  colorUraniumDark,
  colorUraniumDarker,
  colorUraniumLight,
  colorUraniumLighter,
} = color;

module.exports = {
  active: newColor,
  'active-darker': colorInk,
  'active-lighter': colorInkLight,
  chromium: colorChromium,
  'chromium-dark': colorChromiumDark,
  'chromium-darker': colorChromiumDarker,
  'chromium-light': colorChromiumLight,
  'chromium-lighter': colorChromiumLighter,
  crimson: colorCrimson,
  'crimson-dark': colorCrimsonDark,
  'crimson-darker': colorCrimsonDarker,
  'crimson-light': colorCrimsonLight,
  'crimson-lighter': colorCrimsonLighter,
  disabled: colorUiDarker,
  ink: colorInk,
  'ink-dark': colorInkDark,
  'ink-light': colorInkLight,
  'ink-lighter': colorInkLighter,
  sodium: colorSodium,
  'sodium-dark': colorSodiumDark,
  'sodium-darker': colorSodiumDarker,
  'sodium-light': colorSodiumLight,
  'sodium-lighter': colorSodiumLighter,
  ui: colorUi,
  'ui-dark': colorUiDark,
  'ui-darker': colorUiDarker,
  'ui-light': colorUiLight,
  'ui-white': colorUiWhite,
  uranium: colorUranium,
  'uranium-dark': colorUraniumDark,
  'uranium-darker': colorUraniumDarker,
  'uranium-light': colorUraniumLight,
  'uranium-lighter': colorUraniumLighter,
};
