/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_email_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M18 5.5V6l-8 3.86L2 6v-.5A1.5 1.5 0 0 1 3.5 4h13A1.5 1.5 0 0 1 18 5.5zm-8 6.36a2 2 0 0 1-1-.26L2 8v6.5A1.5 1.5 0 0 0 3.5 16h13a1.5 1.5 0 0 0 1.5-1.5V8l-7 3.6a2 2 0 0 1-1 .26z"/>',
  },
});
