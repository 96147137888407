/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_blog: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21.5 3h-14a.5.5 0 0 0-.5.5V9H3.5A1.5 1.5 0 0 0 2 10.5v7A3.5 3.5 0 0 0 5.5 21h14a2.5 2.5 0 0 0 2.5-2.5v-15a.5.5 0 0 0-.5-.5zM7 17.5a1.5 1.5 0 0 1-3 0V11h3zm12 0a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5zm0-4a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5zm0-5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5z"/>',
  },
});
