/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_user: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M16 7a4 4 0 1 1-4-4 4 4 0 0 1 4 4zm4 9.88a3 3 0 0 0-1.92-2.81A17.28 17.28 0 0 0 12 13a17.28 17.28 0 0 0-6.08 1.07A3 3 0 0 0 4 16.88v3.62a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5z"/>',
  },
});
