<template>
  <div>
    <bl-tabs-vertical-mv :tabs="filterTabs" :activeTabName="activeFilterTab">
      <filter-tab-nav slot="tab-nav-price_range" title="Rentang Harga" :show-icon="!!filtersData.price_range" />
      <filter-tab-nav slot="tab-nav-condition" title="Kondisi Barang" :show-icon="!!filtersData.condition" />
      <filter-tab-nav slot="tab-nav-rating" title="Rating" :show-icon="!!filtersData.rating" />
      <filter-tab-nav
        slot="tab-nav-free_shipping_provinces"
        title="Gratis Ongkir"
        :show-icon="!!filtersData.free_shipping_provinces"
      />
      <filter-tab-nav slot="tab-nav-couriers" title="Jasa Pengiriman" :show-icon="!!filtersData.couriers.length" />
      <filter-tab-nav slot="tab-nav-others" title="Lainnya" :show-icon="showFilterOthersIcon" />
      <filter-price-range
        slot="tab-content-price_range"
        :product-price-range="filtersData.price_range"
        @product-price-range-change="filtersData.price_range = $event"
        @filter-error="isNotValid = $event"
      />
      <filter-condition
        slot="tab-content-condition"
        :product-condition="filtersData.condition"
        @product-condition-change="filtersData.condition = $event"
      />
      <filter-rating
        slot="tab-content-rating"
        :product-rating="filtersData.rating"
        @product-rating-change="filtersData.rating = $event"
      />
      <filter-free-shipping
        slot="tab-content-free_shipping_provinces"
        :product-free-shipping="filtersData.free_shipping_provinces"
        @product-free-shipping-change="filtersData.free_shipping_provinces = $event"
      />
      <filter-couriers
        slot="tab-content-couriers"
        :product-couriers="filtersData.couriers"
        @product-couriers-change="filtersData.couriers = $event"
      />
      <filter-others
        slot="tab-content-others"
        :product-deal="filtersData.deal"
        :product-installment="filtersData.installment"
        :product-wholesale="filtersData.wholesale"
        @product-deal-change="filtersData.deal = $event"
        @product-installment-change="filtersData.installment = $event"
        @product-wholesale-change="filtersData.wholesale = $event"
      />
    </bl-tabs-vertical-mv>
    <bl-button-group-mv
      class="u-width-1 u-mrgn--0 u-pad--2 u-bg--white u-position-fixed u-border-top--1--sand-dark spica-filter__button-group"
    >
      <bl-button-av class="u-pad--0 ut-filter-button-reset" @click="reset" variation="secondary"
        >Reset Semua</bl-button-av
      >
      <bl-button-av @click="applyFilter" variation="primary" class="ut-filter-button-apply" :disabled="isNotValid"
        >Terapkan</bl-button-av
      >
    </bl-button-group-mv>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BlButtonGroupMv, BlButtonAv } from '@bukalapak/bazaar-mweb';
import { BlTabsVerticalMv } from '@bukalapak/bazaar-snowflake-mweb';
import FilterTabNav from './components/FilterTabNav';
import FilterPriceRange from './components/FilterPriceRange';
import FilterCondition from './components/FilterCondition';
import FilterRating from './components/FilterRating';
import FilterFreeShipping from './components/FilterFreeShipping';
import FilterCouriers from './components/FilterCouriers';
import FilterOthers from './components/FilterOthers';

export default {
  name: 'MerchantPageFilter',
  components: {
    FilterPriceRange,
    FilterCondition,
    FilterRating,
    FilterFreeShipping,
    FilterCouriers,
    FilterOthers,
    BlButtonGroupMv,
    BlButtonAv,
    BlTabsVerticalMv,
    FilterTabNav,
  },
  data() {
    return {
      filtersData: {},
      activeFilterTab: 'price_range',
      filterTabs: ['price_range', 'condition', 'rating', 'free_shipping_provinces', 'couriers', 'others'],
      isNotValid: false,
    };
  },
  computed: {
    ...mapState('store', ['filters']),
    showFilterOthersIcon() {
      return !!this.filtersData.deal || !!this.filtersData.installment || !!this.filtersData.wholesale;
    },
  },
  created() {
    this.filtersData = { ...this.filters };
  },
  methods: {
    reset() {
      this.filtersData.price_range = '';
      this.filtersData.condition = '';
      this.filtersData.rating = '';
      this.filtersData.free_shipping_provinces = '';
      this.filtersData.couriers = [];
      this.filtersData.deal = '';
      this.filtersData.installment = '';
      this.filtersData.wholesale = '';
    },
    async applyFilter() {
      await this.$store.dispatch('store/UPDATE_FILTERS', { ...this.filtersData, offset: 0 });
      this.$emit('apply-filter');
    },
  },
};
</script>
<style lang="scss">
.spica-filter {
  &__button-group {
    bottom: 0;
    z-index: 10;
    left: 0;
    bottom: 0;
  }
}
.bl-sheet__content {
  margin-bottom: 0 !important;
}
.bl-tabs-vertical {
  &__action {
    position: relative !important;
  }
  &-container--content {
    height: calc(100vh - 80px);
    padding-top: 0;
    padding-bottom: 60px;
  }
}
</style>
