/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_send: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21.15 13.35l-17.7 8.54A1 1 0 0 1 2 20.82l1.63-7.24a.49.49 0 0 1 .41-.39L12 12l-7.94-1.19a.49.49 0 0 1-.41-.39L2 3.18a1 1 0 0 1 1.45-1.07l17.7 8.54a1.5 1.5 0 0 1 0 2.7z"/>',
  },
});
