/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_reply_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11 17v-3C4 14 3 9 3 4h1c0 3 3 6 7 6V7l7 5z"/>',
  },
});
