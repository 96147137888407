<template>
  <section class="spica-container">
    <merchant-page-fixed-header
      @back-clicked="backIconClicked"
      @apply-filter="applyKeywords"
      @open-menu-lapak="openMenuLapak = true"
    />
    <transition name="slide-left">
      <merchant-page-search-result
        class="u-bg--sand-light u-width-1 spica__search-result"
        :class="resultPageTransitionDone ? 'u-position-absolute' : 'u-position-fixed'"
        v-if="!isStoreLoading && isResultPageActive"
        @open-menu-lapak="openMenuLapak = true"
        @open-label-sheet="openLabelSheet = true"
        @open-sort-sheet="openSortSheet = true"
        @open-filter-sheet="openFilterSheet = true"
      />
    </transition>
    <merchant-page-index
      ref="merchantPageIndex"
      class="u-bg--sand-light u-position-absolute u-width-1"
      :class="[
        { 'u-overflow--hidden spica__index--max-height': resultPageTransitionDone },
        { spica__index: isResultPageActive },
      ]"
      @apply-filter="applyFilter"
      @open-menu-lapak="openMenuLapak = true"
      @open-detail-info="openDetailInfo = true"
      @open-label-sheet="openLabelSheet = true"
      @open-sort-sheet="openSortSheet = true"
      @open-filter-sheet="openFilterSheet = true"
    />
    <!-- sheet -->
    <bl-sheet-mv :active.sync="openMenuLapak" title="Menu Lapak" autoHeight>
      <merchant-page-menu
        style="padding: 16px 12px;"
        slot="content"
        @open-share-lapak="openShareLapakSheet"
        @open-detail-info="openDetailInfoSheet"
      />
    </bl-sheet-mv>
    <bl-sheet-mv class="detail-info" :active.sync="openDetailInfo" title="Detail Info Lapak">
      <div slot="content">
        <merchant-page-detail-info />
      </div>
    </bl-sheet-mv>
    <bl-sheet-mv :active.sync="openShareLapak" title="Share Lapak" autoHeight>
      <merchant-page-share style="padding: 16px 12px;" slot="content" />
    </bl-sheet-mv>
    <bl-sheet-mv :active.sync="openLabelSheet" title="Etalase Barang" autoHeight>
      <div style="padding: 16px 12px;" slot="content">
        <merchant-page-etalase @apply-filter="applyFilter" />
      </div>
    </bl-sheet-mv>
    <bl-sheet-mv :active.sync="openSortSheet" title="Urutkan Barang" autoHeight>
      <div style="padding: 16px 12px;" slot="content">
        <merchant-page-sort @apply-filter="applyFilter" />
      </div>
    </bl-sheet-mv>
    <bl-sheet-mv class="product-filter" :active.sync="openFilterSheet" title="Filter Barang">
      <merchant-page-filter @apply-filter="applyFilter" slot="content" />
    </bl-sheet-mv>
  </section>
</template>

<script>
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import MerchantPageRouteMixin from 'shared/javascripts/mixins/merchant-page/route';
import { removeProperties } from 'shared/javascripts/utils/object-helpers';
import { trackPageviewGA, trackPageview } from 'shared/javascripts/utils/tracker';
import { mapState, mapGetters } from 'vuex';
import defaultFilters from 'shared/data/default-filters';
import MerchantPageFixedHeader from '../../components/MerchantPageFixedHeader';
import MerchantPageIndex from '../MerchantPageIndex';
import MerchantPageSearchResult from '../MerchantPageSearchResult';
import MerchantPageDetailInfo from '../../components/MerchantPageDetailInfo';
import MerchantPageMenu from '../../components/MerchantPageMenu';
import MerchantPageShare from '../../components/MerchantPageShare';
import MerchantPageFilter from '../../components/MerchantPageFilter';
import MerchantPageEtalase from '../../components/MerchantPageEtalase';
import MerchantPageSort from '../../components/MerchantPageSort';

const BlSheetMv = () =>
  import(/* webpackChunkName: "BlSheetMv-merchantpage" */ '@bukalapak/bazaar-mweb/dist/components/BlSheetMv');

export default {
  name: 'MerchantPage',
  components: {
    MerchantPageIndex,
    MerchantPageSearchResult,
    MerchantPageMenu,
    MerchantPageDetailInfo,
    MerchantPageShare,
    MerchantPageFilter,
    MerchantPageEtalase,
    MerchantPageSort,
    MerchantPageFixedHeader,
    BlSheetMv,
  },
  mixins: [StoreStateMixin, MerchantPageRouteMixin],
  data() {
    return {
      limit: 12,
      openDetailInfo: false,
      openShareLapak: false,
      openMenuLapak: false,
      openFilterSheet: false,
      openSortSheet: false,
      openLabelSheet: false,
      isResultPageActive: false,
      resultPageTransitionDone: false,
    };
  },
  computed: {
    ...mapState('store', ['store', 'labels', 'filters', 'isStoreLoading', 'persistedStoreUrl']),
    ...mapGetters('store', ['isProductsEmpty']),
    currentLabel() {
      return this.labels.find(e => e.id === this.filters.label_id);
    },
    currentPath() {
      return `${this.routePath}${this.currentLabel ? `/label/${this.currentLabel.slug}` : ''}`;
    },
    labelSlug() {
      return this.$route.params.label_slug;
    },
    filteredLabels() {
      return this.labels.filter(label => label.slug === this.labelSlug);
    },
  },
  watch: {
    isResultPageActive(val) {
      const focusBodyElement = document.querySelector('body');
      if (val) {
        focusBodyElement.style.overflow = 'hidden';
        setTimeout(() => {
          focusBodyElement.style.overflow = 'auto';
          window.scroll({ top: 0 });
          this.resultPageTransitionDone = true;
        }, 500);
      } else {
        this.resultPageTransitionDone = false;
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    initPersistedState() {
      const { persistedStoreUrl } = this;
      const { pathname, search } = window.location;
      const storeUrl = `${pathname}${search}`;

      if (persistedStoreUrl && persistedStoreUrl !== storeUrl) {
        // reset products state when store changed
        this.$store.commit('store/resetProducts');
      }
    },
    async init() {
      this.initPersistedState();
      this.isResultPageActive = !!this.$route.query.keywords;
      await this.updateFilterFromRoute();
      await this.$store.dispatch('store/GET_LABELS');
      await this.initLabelSlug();
      this.$store.commit('store/updateLoadingStore', false);
      this.$store.dispatch('toggleFeatures/GET_ALL_TOGGLE');
      this.$store.dispatch('chat/RETRIEVE_LAST_SEEN', this.store.id);
      this.$store.dispatch('chat/RETRIEVE_ONLINE_STATUS', this.store.id);

      if (this.isProductsEmpty) {
        this.$store.dispatch('store/GET_PRODUCTS');
      }

      this.$store.dispatch('productHighlights/GET_PRODUCT_HIGHLIGHTS', this.store.id);
      this.$store.dispatch('store/GET_STORE_FEEDBACKS', { limit: 3 });
      this.$store.dispatch('store/GET_STORE_VOUCHERS', { id: this.store.id });
      this.$store.dispatch('store/GET_COURIERS');
    },
    async updateFilterFromRoute() {
      const filtersResult = removeProperties(this.$route.query, ['limit', 'offset', 'store_id', 'label_id']);
      if (filtersResult.couriers && !Array.isArray(filtersResult.couriers)) {
        filtersResult.couriers = [filtersResult.couriers];
      }
      await this.$store.dispatch('store/UPDATE_FILTERS', {
        ...filtersResult,
        offset: 0,
        store_id: this.store.id,
        limit: this.limit,
      });
    },
    async initLabelSlug() {
      if (this.labelSlug && this.filteredLabels.length > 0) {
        await this.$store.dispatch('store/UPDATE_FILTERS', { label_id: this.filteredLabels[0].id });
      }
    },
    async applyFilter(clickedBackButton) {
      const documentReferrer = window.location.href;
      this.openLabelSheet = false;
      this.openSortSheet = false;
      this.openFilterSheet = false;
      if (!this.isResultPageActive && !clickedBackButton) {
        const scrollPos = this.$el.querySelector('.spica-product-list').offsetTop;
        window.scroll({
          top: scrollPos - 56,
        });
      }
      if (this.isResultPageActive) {
        window.scroll({ top: 0 });
      }
      const filtersResult = removeProperties(this.filters, ['limit', 'offset', 'store_id', 'label_id']);
      this.$router.replace({
        path: this.currentPath,
        query: {
          ...filtersResult,
        },
      });
      trackPageviewGA();
      trackPageview(documentReferrer);
      try {
        await this.$store.dispatch('store/GET_PRODUCTS');
      } catch (e) {
        this.$root.notifyError(e, 'applyFilter', { filtersResult }, this.$options.name);
      }
    },
    async applyKeywords() {
      const documentReferrer = window.location.href;
      if (this.isResultPageActive || (!this.isResultPageActive && !!this.filters.keywords)) {
        const filtersResult = removeProperties(this.filters, ['limit', 'offset', 'store_id', 'label_id']);
        this.$router.replace({
          path: this.currentPath,
          query: {
            ...filtersResult,
          },
        });
        trackPageviewGA();
        trackPageview(documentReferrer);
        if (this.isResultPageActive) {
          window.scroll({ top: 0 });
        }
        this.isResultPageActive = true;
        try {
          await this.$store.dispatch('store/GET_PRODUCTS');
        } catch (e) {
          this.$root.notifyError(e, 'applyKeywords', { filtersResult }, this.$options.name);
        }
      }
    },
    openDetailInfoSheet() {
      this.openMenuLapak = false;
      setTimeout(() => {
        this.openDetailInfo = true;
      }, 200);
    },
    openShareLapakSheet() {
      this.openMenuLapak = false;
      setTimeout(() => {
        this.openShareLapak = true;
      }, 200);
    },
    async backIconClicked() {
      if (this.isResultPageActive) {
        this.isResultPageActive = false;
        const resetFilter = removeProperties(defaultFilters(), ['limit', 'store_id', 'label_id', 'sort']);
        await this.$store.dispatch('store/UPDATE_FILTERS', resetFilter);
        await this.applyFilter(true);
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.spica {
  &-container {
    z-index: 1;
    position: relative;
  }
  &__search-result {
    z-index: 2;
    min-height: calc(100vh - 55px);
  }
  &__index {
    z-index: 1;
    &--max-height {
      height: calc(100vh - 56px);
    }
  }
}
.slide-left-enter-active,
.slide-left-leave-active {
  position: relative;
  left: 0;
  transition: left 0.5s;
}
.slide-left-enter, .slide-left-leave-to /* .fade-leave-active below version 2.1.8 */ {
  left: 100vh;
}
</style>
