/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_verified_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M17.57 8.94L16 7.37V5.5A1.5 1.5 0 0 0 14.5 4h-1.87l-1.57-1.57a1.49 1.49 0 0 0-2.12 0L7.37 4H5.5A1.5 1.5 0 0 0 4 5.5v1.87L2.43 8.94a1.49 1.49 0 0 0 0 2.12L4 12.63v1.87A1.5 1.5 0 0 0 5.5 16h1.87l1.57 1.57a1.49 1.49 0 0 0 2.12 0L12.63 16h1.87a1.5 1.5 0 0 0 1.5-1.5v-1.87l1.57-1.57a1.49 1.49 0 0 0 0-2.12zm-3.86-.23l-4 4a1 1 0 0 1-1.42 0l-2-2a1 1 0 0 1 1.42-1.42L9 10.59l3.29-3.3a1 1 0 1 1 1.42 1.42z"/>',
  },
});
