/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_location_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M10 2a6 6 0 0 0-6 6c0 2.81 4.31 8.29 5.62 9.83a.5.5 0 0 0 .76 0C11.69 16.29 16 10.81 16 8a6 6 0 0 0-6-6zm0 8a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/>',
  },
});
