/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_back_ios: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M15.66 18.25a1 1 0 0 1 .09 1.41A1 1 0 0 1 15 20a1 1 0 0 1-.66-.25l-8-7a1 1 0 0 1 0-1.5l8-7a1 1 0 1 1 1.32 1.5L8.52 12z"/>',
  },
});
