/* eslint-disable */
import './ico_account_box';
import './ico_add_circle';
import './ico_add_minor';
import './ico_alert';
import './ico_back_android';
import './ico_back_ios';
import './ico_bill';
import './ico_blog';
import './ico_calendar_minor';
import './ico_call_minor';
import './ico_camera_minor';
import './ico_camera';
import './ico_cart';
import './ico_cash';
import './ico_category_minor';
import './ico_chat';
import './ico_check_circle';
import './ico_check_minor';
import './ico_chevron_calendar_minor';
import './ico_chevron_down_minor';
import './ico_chevron_left_minor';
import './ico_chevron_right_minor';
import './ico_chevron_up_minor';
import './ico_comment_minor';
import './ico_contact_book';
import './ico_credit_card';
import './ico_cross_circle_minor';
import './ico_cross_circle';
import './ico_cross_minor';
import './ico_discount';
import './ico_discussion';
import './ico_document';
import './ico_download';
import './ico_duplicate_minor';
import './ico_edit_minor';
import './ico_email_minor';
import './ico_email';
import './ico_eticket';
import './ico_external_link_minor';
import './ico_filter_minor';
import './ico_flag';
import './ico_graph';
import './ico_grid_view_minor';
import './ico_group';
import './ico_guarantee_minor';
import './ico_guarantee';
import './ico_heart_outline';
import './ico_heart';
import './ico_history_minor';
import './ico_history';
import './ico_home';
import './ico_image';
import './ico_info_minor';
import './ico_info';
import './ico_list_view_minor';
import './ico_location_minor';
import './ico_location';
import './ico_lock';
import './ico_login';
import './ico_menu';
import './ico_more_horizontal';
import './ico_more_vertical';
import './ico_move_vert';
import './ico_notification_minor';
import './ico_notification_off_minor';
import './ico_notification_off';
import './ico_notification';
import './ico_phone';
import './ico_play_outlined';
import './ico_play';
import './ico_polling';
import './ico_portfolio';
import './ico_premium_account';
import './ico_printer';
import './ico_product';
import './ico_promoted_push';
import './ico_promotion';
import './ico_push';
import './ico_reply_minor';
import './ico_scan_barcode';
import './ico_search_minor';
import './ico_search';
import './ico_send';
import './ico_setting';
import './ico_share_android';
import './ico_share_ios';
import './ico_shipping';
import './ico_shop';
import './ico_sort_minor';
import './ico_star';
import './ico_tag_label';
import './ico_target';
import './ico_thumbsdown';
import './ico_thumbsup';
import './ico_time_minor';
import './ico_transaction';
import './ico_trash';
import './ico_ui_cross';
import './ico_ui_plus';
import './ico_ui_tick';
import './ico_user';
import './ico_verified_minor';
import './ico_verified';
import './ico_verify';
import './ico_view_history_minor';
import './ico_voucher';
