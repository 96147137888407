/* eslint-disable no-undef */
import { getUrl, wrap } from '../../utils/api-helpers';
import { urlRequest } from '../../utils/secure-client';

const interfaces = {
  getStoreFeedbacks(id, query) {
    return this.get(urlRequest(getUrl(`/stores/${id}/feedbacks`), '', query));
  },
};

const wrappedInterfaces = wrap(interfaces, 'xhrFeedbacks');

export default wrappedInterfaces;
