/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_image: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3zM19 5v10.22A9.33 9.33 0 0 0 12 12a10.39 10.39 0 0 0-7 2.93V5zm-5 3.5a1.5 1.5 0 1 1 1.5 1.5A1.5 1.5 0 0 1 14 8.5z"/>',
  },
});
