/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_transaction: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M18.5 4h-.43l-.41.61-.7 1A3 3 0 0 1 14.46 7H9.54A3 3 0 0 1 7 5.66l-.7-1L5.93 4H5.5A1.5 1.5 0 0 0 4 5.5v15A1.5 1.5 0 0 0 5.5 22h13a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 18.5 4zM12 19H8a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2zm4-4H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2zM8.33 4L8 3.5l.7-1a1 1 0 0 1 .84-.5h4.92a1 1 0 0 1 .84.45l.7 1-.33.5-.37.55a1 1 0 0 1-.84.45H9.54a1 1 0 0 1-.84-.45L8.33 4z"/>',
  },
});
