/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_ui_cross: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M18.71 17.29a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0L12 13.41l-5.29 5.3a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42l5.3-5.29-5.3-5.29a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.3a1 1 0 1 1 1.42 1.42L13.41 12z"/>',
  },
});
