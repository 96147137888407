/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_thumbsup: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M7 10v9a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm13.5-1H15a11 11 0 0 0 .33-5.3 1 1 0 0 0-1-.7h-.43a1 1 0 0 0-1 .83 8.1 8.1 0 0 1-3.41 5 1 1 0 0 0-.49.88V18a2 2 0 0 0 2 2h8.05a1.5 1.5 0 0 0 1.47-1.23l1.46-8A1.51 1.51 0 0 0 20.5 9z"/>',
  },
});
