/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_edit_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M10.5 5.48l4 4L6 18H2v-4zm7.18-.83l-2.33-2.33a1.28 1.28 0 0 0-1.81.12L12 4l4 4 1.56-1.51a1.29 1.29 0 0 0 .12-1.84z"/>',
  },
});
