<template>
  <div>
    <MerchantPageAgeRestrictionPopup v-if="isAgeRestricted" />
    <MerchantPage v-if="store.id" />
    <flash />
  </div>
</template>

<script>
import '@bukalapak/bazaar-mweb/dist/bazaar.css';
import '@bukalapak/bazaar-snowflake-mweb/dist/bazaar.css';
import MerchantPageAppMixin from 'shared/javascripts/mixins/merchant-page/app_mweb';
import MerchantPage from './pages/MerchantPage';

const MerchantPageAgeRestrictionPopup = () =>
  import(/* webpackChunkName: "MerchantPageAgeRestrictionPopup" */ './components/MerchantPageAgeRestrictionPopup');

export default {
  name: 'MerchantPageApp',
  mixins: [MerchantPageAppMixin],
  components: {
    MerchantPage,
    MerchantPageAgeRestrictionPopup,
  },
};
</script>
