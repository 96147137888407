/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_guarantee_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M10 2a23.76 23.76 0 0 1-7 2.5C3 14 6 16 10 18c4-2 7-4 7-13.5A23.76 23.76 0 0 1 10 2zm1.15 2.78h.11c.35.15.7.3 1.07.44h.1c.37.14.75.27 1.15.4l.45.13c.3.09.6.18.93.26-.29 6.11-2.36 8.21-5 9.66-2.6-1.45-4.67-3.55-5-9.66.33-.08.63-.17.93-.26l.45-.13c.35-.11.69-.23 1-.35l.23-.08 1-.43h.14L10 4.24zM10 5.9a24.77 24.77 0 0 0 3.38 1.3C13 11.1 11.71 12.82 10 14c-1.71-1.18-3-2.9-3.38-6.8A24.77 24.77 0 0 0 10 5.9z"/><path pid="1" d="M10 2a23.76 23.76 0 0 1-7 2.5C3 14 6 16 10 18c4-2 7-4 7-13.5A23.76 23.76 0 0 1 10 2zm5 4.08c-.29 6.11-2.36 8.21-5 9.66-2.6-1.45-4.67-3.55-5-9.66a24.8 24.8 0 0 0 5-1.84 24.1 24.1 0 0 0 5 1.84zm-5-.18a24.77 24.77 0 0 0 3.38 1.3C13 11.1 11.71 12.82 10 14c-1.71-1.18-3-2.9-3.38-6.8A24.77 24.77 0 0 0 10 5.9z"/>',
  },
});
