/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_verify: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M9 4V3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm11 .5v16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 20.5v-16A1.5 1.5 0 0 1 5.5 3H7v1a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3V3h1.5A1.5 1.5 0 0 1 20 4.5zm-4.38 6.72a1 1 0 0 0-1.4.16l-3.3 4.12-1.21-1.21a1 1 0 0 0-1.42 1.42l2 2A1 1 0 0 0 11 18h.06a1 1 0 0 0 .72-.38l4-5a1 1 0 0 0-.16-1.4z"/>',
  },
});
