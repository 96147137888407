/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_premium_account: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm5.88 8.5l-2.62 2.69.61 3.81a.46.46 0 0 1-.67.48l-3.2-1.8-3.17 1.77a.46.46 0 0 1-.68-.45l.61-3.78-2.62-2.72a.46.46 0 0 1 .27-.78L10 9.17l1.58-3.4a.47.47 0 0 1 .84 0L14 9.17l3.6.55a.46.46 0 0 1 .28.78z"/>',
  },
});
