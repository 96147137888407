/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_contact_book: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M22 4.5V7a1 1 0 0 1-1 1h-2V4h2.5a.5.5 0 0 1 .5.5zm-.5 5.5H19v4h2a1 1 0 0 0 1-1v-2.5a.5.5 0 0 0-.5-.5zm0 6H19v4h2a1 1 0 0 0 1-1v-2.5a.5.5 0 0 0-.5-.5zm-4-12.5v16A1.5 1.5 0 0 1 16 21H3.5A1.5 1.5 0 0 1 2 19.5v-16A1.5 1.5 0 0 1 3.5 2H16a1.5 1.5 0 0 1 1.5 1.5zM7 9.5A2.5 2.5 0 1 0 9.5 7 2.5 2.5 0 0 0 7 9.5zm8 8.5a5.28 5.28 0 0 0-5.12-4h-.76A5.28 5.28 0 0 0 4 18h11z"/>',
  },
});
