/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_account_box: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M19.5 2h-15A1.5 1.5 0 0 0 3 3.5v17A1.5 1.5 0 0 0 4.5 22h15a1.5 1.5 0 0 0 1.5-1.5v-17A1.5 1.5 0 0 0 19.5 2zM12 6a3 3 0 1 1-3 3 3 3 0 0 1 3-3zM6 19l.51-1.29a5.92 5.92 0 0 1 11 0L18 19z"/>',
  },
});
