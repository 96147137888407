<template>
  <bl-flex-container-mv
    direction="column"
    v-if="productHighlights.length"
    justify-content="flex-start"
    class="u-bg--white"
    padding="18px 0px"
    margin="12px 0 0 0"
  >
    <bl-flex-item-av class="u-width-1" grow="1" v-for="(productHighlight, idx) in productHighlights" :key="idx">
      <div class="u-mrgn-bottom--3 u-mrgn-left--4 u-txt--bold u-txt--medium">
        {{ productHighlight.name }}
      </div>
      <bl-scrollerbox-mv
        :itemLength="productHighlight.products.length"
        itemWidth="132px"
        itemSpace="12px"
        class="spica-scroller-box ph-section"
      >
        <a
          v-for="(item, idx) in productHighlight.products"
          :slot="`item-${idx + 1}`"
          :key="`pd-${item.id}`"
          class="u-pointer"
          style="color: unset;"
          :href="productUrl(item)"
          rel="nofollow"
          @mousedown="sendTrackerHitProductHighlight(item, idx, productHighlight.name)"
        >
          <mini-product-card :product="item" />
        </a>
      </bl-scrollerbox-mv>
      <div v-if="idx + 1 !== productHighlights.length" class="u-hr u-mrgn-h--4" />
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import { BlScrollerboxMv } from '@bukalapak/bazaar-snowflake-mweb';
import MiniProductCard from 'shared/javascripts/components/MiniProductCard';
import { mapState } from 'vuex';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { trackProductHighlight } from 'shared/javascripts/utils/tracker';

export default {
  name: 'MerchantPageFeedback',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    MiniProductCard,
    BlScrollerboxMv,
  },
  mixins: [StoreStateMixin],
  data() {
    return {
      isPWABukaGames: false,
    };
  },
  computed: {
    ...mapState('store', ['store']),
    ...mapState('productHighlights', ['productHighlights']),
  },
  mounted() {
    this.getPWAGamesState();
  },
  methods: {
    getPWAGamesState() {
      this.isPWABukaGames = typeof window !== 'undefined' && window.sessionStorage.getItem('isPWABukaGames') === 'true';
    },
    productUrl(item) {
      const productOwner = this.store.brand_seller ? 'brand_seller' : 'normal_seller';
      let { url } = item;
      if (this.isPWABukaGames) url = url.replace('www.', 'm.');
      return `${url}?from=product_owner_product_highlights&product_owner=${productOwner}`;
    },
    sendTrackerHitProductHighlight(product, position, sectionName) {
      trackProductHighlight({
        merchantId: this.store.id,
        sectionName,
        productId: product.id,
        position,
      });
    },
  },
};
</script>
