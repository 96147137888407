<template>
  <div class="spica-header-info u-position-relative">
    <bl-flex-container-mv
      class="u-margin-top--0 u-bg--white u-border-radius--13"
      align-content="flex-start"
      padding="12px 16px"
    >
      <bl-flex-item-av class="spica-bl-avatar-container">
        <bl-avatar-av class="spica-bl-avatar u-loading-gradient" size="large"> </bl-avatar-av>
      </bl-flex-item-av>
      <bl-flex-item-av class="u-mrgn-left--4" grow="1">
        <bl-flex-container-mv direction="column" align-content="flex-start">
          <bl-flex-item-av class="u-txt--medium u-txt--bold">
            <bl-flex-container-mv align-content="flex-start">
              <bl-flex-item-av grow="1">
                <bl-preloader
                  class="u-txt--medium u-pad-bottom--1"
                  :wrapper-style="{ width: '30%' }"
                  loading-class="u-border-radius--2"
                />
              </bl-flex-item-av>
            </bl-flex-container-mv>
          </bl-flex-item-av>
          <bl-flex-item-av class="u-txt--fair u-fg--ash-dark u-mrgn-bottom--2">
            <bl-preloader class="u-txt--fair" :wrapper-style="{ width: '40%' }" loading-class="u-border-radius--2" />
          </bl-flex-item-av>
        </bl-flex-container-mv>
      </bl-flex-item-av>
    </bl-flex-container-mv>
    <bl-flex-container-mv class="u-margin-top--0 u-bg--white u-txt--center" padding="0px 16px 12px">
      <bl-flex-item-av grow="1">
        <div class="u-pointer">
          <div>
            <bl-icon-av class="v u-mrgn-right--1" name="ico_thumbsup" color="active-darker" />
          </div>
          <div class="u-fg--ash-dark u-mrgn-top--1">
            <bl-preloader class="u-txt--fair u-mrgn-h--5" loading-class="u-border-radius--2" />
          </div>
        </div>
      </bl-flex-item-av>
      <bl-flex-item-av grow="1">
        <div class="u-pointer">
          <div>
            <bl-icon-av class="u-valign-middle" name="ico_time_minor" color="active-darker" />
          </div>
          <div class="u-fg--ash-dark u-mrgn-top--1">
            <bl-preloader class="u-txt--fair u-mrgn-h--5" loading-class="u-border-radius--2" />
          </div>
        </div>
      </bl-flex-item-av>
      <bl-flex-item-av grow="1">
        <div class="u-pointer">
          <div><bl-icon-av class="u-valign-middle" name="ico_info_minor" color="active-darker" /></div>
          <div class="u-fg--ash-dark u-mrgn-top--1">
            <bl-preloader class="u-txt--fair u-mrgn-h--5" loading-class="u-border-radius--2" />
          </div>
        </div>
      </bl-flex-item-av>
    </bl-flex-container-mv>
  </div>
</template>

<script>
import BlIconAv from 'shared/javascripts/components/BlIconAv';
import { BlFlexContainerMv, BlFlexItemAv, BlAvatarAv } from '@bukalapak/bazaar-mweb';
import BlPreloader from '@bukalapak/catalyst-dweb/dist/BlPreloader';

export default {
  name: 'MerchantPageHeaderInfo',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlAvatarAv,
    BlIconAv,
    BlPreloader,
  },
};
</script>

<style src="../MerchantPageHeaderInfo/MerchantPageHeaderInfo.style.scss" lang="scss" scoped></style>
