<template>
  <svgicon :name="name" :width="size" :height="size" :color="color ? colorHex : null" />
</template>
<script>
import 'plugins/vue-svgicon';
import icons from './data/name.json';
import colors from './data/colors';

export default {
  name: 'BlIconAv',
  props: {
    /**
     * Icon name
     */
    name: {
      type: String,
      required: true,
      validator(value) {
        return icons.indexOf(value) >= 0;
      },
    },
    /**
     * Color of the icon, optional
     */
    color: {
      type: String,
      default: 'active',
      validator(value) {
        return Object.keys(colors).indexOf(value) >= 0 || value.charAt(0) === '#' || value === null;
      },
    },
    size: {
      type: String,
      default: '20',
    },
  },
  data() {
    return {
      colors,
    };
  },
  created() {
    /* eslint-disable-next-line */
    require(`./icons/${this.name}`);
  },
  computed: {
    colorHex() {
      const value = this.colors[this.color];
      if (value) {
        let colorFix;
        if (!value.includes('#')) {
          const rgb = value.replace(/\s/g, '').match(/^rgb?\((\d+),(\d+),(\d+)/i);
          const r = (0 + parseInt(rgb[1], 10).toString(16)).slice(-2);
          const g = (0 + parseInt(rgb[2], 10).toString(16)).slice(-2);
          const b = (0 + parseInt(rgb[3], 10).toString(16)).slice(-2);
          colorFix = rgb && rgb.length === 4 ? `#${r}${g}${b}` : value;
        } else {
          colorFix = value;
        }
        return colorFix;
      }
      return this.color;
    },
  },
};
</script>
