/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_discount: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21.53 10.86L20 9.33V6.16a1.66 1.66 0 0 0-1.66-1.66h-3.17l-2-2a1.61 1.61 0 0 0-2.28 0l-2 2H5.66A1.66 1.66 0 0 0 4 6.16v3.17l-1.53 1.53a1.61 1.61 0 0 0 0 2.28L4 14.67v3.17a1.66 1.66 0 0 0 1.66 1.66h3.17l2 2a1.61 1.61 0 0 0 2.28 0l2-2h3.17A1.66 1.66 0 0 0 20 17.84v-3.17l1.53-1.53a1.61 1.61 0 0 0 0-2.28zM7 9.5A1.45 1.45 0 0 1 8.4 8a1.5 1.5 0 0 1 0 3A1.45 1.45 0 0 1 7 9.5zM9.94 16H8.06l6-8h1.88zm5.66 0a1.5 1.5 0 1 1 1.4-1.5 1.45 1.45 0 0 1-1.4 1.5z"/>',
  },
});
