/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_view_history_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M15.71 15.71a1 1 0 0 1-1.42 0L6 7.41V13a1 1 0 0 1-2 0V5a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2H7.41l8.3 8.29a1 1 0 0 1 0 1.42z"/>',
  },
});
