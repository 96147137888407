/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_cross_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M15.71 14.29a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0L10 11.41l-4.29 4.3a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42L8.59 10l-4.3-4.29a1 1 0 0 1 1.42-1.42L10 8.59l4.29-4.3a1 1 0 1 1 1.42 1.42L11.41 10z"/>',
  },
});
