<template>
  <section>
    <!-- image header -->
    <merchant-page-banner />
    <!-- lapak info header -->
    <merchant-page-header-info-loading v-if="isStoreLoading" />
    <merchant-page-header-info
      v-else
      @open-premium-info="openPremiumInfo = true"
      @open-top-seller-info="openTopSellerInfo = true"
      @open-feedback-info="openFeedbackInfo = true"
      @open-delivery-info="openDeliveryInfo = true"
    />
    <!-- ikut & chat -->
    <merchant-page-chat-follow
      :showFollowChatButton="!isCurrentUserStore && !isStoreLoading"
      @open-detail-info="$emit('open-detail-info')"
    />
    <!-- voucher -->
    <merchant-page-voucher @open-voucher-modal="openVoucherModal = true" />
    <!-- product highlight -->
    <merchant-page-highlights />
    <!-- search filter urutkan -->
    <template v-if="!isStoreLoading">
      <merchant-page-action-button
        class="spica-filter"
        @open-label-sheet="$emit('open-label-sheet')"
        @open-sort-sheet="$emit('open-sort-sheet')"
        @open-filter-sheet="$emit('open-filter-sheet')"
      />
    </template>
    <!-- product list -->
    <bl-flex-container-mv
      class="spica-product-list"
      :class="!isStoreInactive ? 'spica__product-list-height' : 'spica__product-list-height--no-product'"
      justify-content="flex-start"
      padding="0 8px 18px"
      margin="12px 0 0 0"
    >
      <bl-flex-item-av class="u-position-relative u-width-1" grow="1">
        <template v-if="!isStoreInactive">
          <merchant-page-product-list-loader v-if="!isResultPageActive && isProductsLoading" />
          <template v-else>
            <merchant-page-product-list v-if="products.length" />
            <infinite-loading
              v-if="!isResultPageActive && !isProductsLoading && hasMoreData"
              @infinite="loadMoreProduct"
              :identifier="infiniteId"
            >
              <div slot="spinner">
                <merchant-page-product-list-loader />
              </div>
            </infinite-loading>
          </template>
        </template>
        <merchant-page-illustration v-if="!isStoreLoading" />
      </bl-flex-item-av>
    </bl-flex-container-mv>
    <!-- SheetMV -->
    <bl-sheet-mv :active.sync="openFeedbackInfo" title="Lebih Detail Tentang Feedback" autoHeight>
      <div style="padding: 16px 12px;" slot="content">
        <div class="u-mrgn-bottom--2">
          <span class="u-txt--bold">{{ feedbackPercentage }}%</span> ({{ totalFeedback }} Feedback)
        </div>
        <div class="u-fg--ash-dark u-mrgn-bottom--4">
          Feedback penjualan adalah penilaian yang diberikan oleh pembeli kepada pelapak atas pelayanan pelapak.
        </div>
        <bl-button-av :href="`/u/${store.owner.username}/feedback/`" class="u-width-1" variation="primary">
          Lihat Semua Feedback
        </bl-button-av>
      </div>
    </bl-sheet-mv>
    <bl-sheet-mv :active.sync="openDeliveryInfo" title="Apa Itu Waktu Proses?" autoHeight>
      <div style="padding: 16px 12px;" slot="content">
        Waktu proses adalah waktu yang dibutuhkan pelapak sejak pembayaran kamu diterima sampai selesai masa pengemasan
        barang.
      </div>
    </bl-sheet-mv>
    <bl-sheet-mv :active.sync="openPremiumInfo" title="Pelapak Premium" autoHeight>
      <div style="padding: 16px 12px;" slot="content">
        <div class="u-txt--center">
          <img class="u-width-8of9" :src="getAssetUrl('images/ill_premium.png')" />
          <div>
            Badge ini hanya didapatkan oleh pelapak Premium yang berhasil mencapai score tertentu pada peforma lapaknya.
          </div>
        </div>
      </div>
      <div style="padding: 0px 12px 16px;" slot="footer">
        <bl-button-av href="/premium/packages/available" class="u-width-1" variation="primary"
          >Berlangganan Premium</bl-button-av
        >
      </div>
    </bl-sheet-mv>
    <bl-sheet-mv :active.sync="openTopSellerInfo" :title="topSellerSheet.title" autoHeight>
      <div style="padding: 16px 12px;" slot="content">
        <div class="u-txt--center u-mrgn-bottom--4">
          <img
            class="u-width-8of9 u-mrgn-bottom--3"
            :class="{ 'spica-super-seller-illus': isSuperSellerActive }"
            :src="getAssetUrl(`images/${topSellerSheet.illus}.png`)"
          />
          <div>
            {{ topSellerSheet.text }}
          </div>
        </div>
        <bl-button-av
          v-if="isSuperSellerActive"
          @click="openTopSellerInfo = false"
          class="u-width-1"
          variation="primary"
          >Tutup</bl-button-av
        >
        <bl-button-av v-else href="/premium/packages/available" class="u-width-1" variation="primary"
          >Berlangganan Premium</bl-button-av
        >
      </div>
    </bl-sheet-mv>
    <bl-sheet-mv :active.sync="openVoucherModal" title="Voucher Untukmu" autoHeight>
      <div class="u-bg--sand-light u-height-1 u-pad-bottom--10" slot="content">
        <revamp-voucher-mobile
          v-for="voucher in vouchers"
          :key="voucher.voucher_code"
          :voucher="voucher"
          class="u-mrgn-bottom--4"
          @code-copied="sendTracker(voucher)"
        />
      </div>
    </bl-sheet-mv>
  </section>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv, BlButtonAv } from '@bukalapak/bazaar-mweb';
import { mapState, mapGetters } from 'vuex';
import AssetsMixin from 'shared/javascripts/mixins/assets';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import { trackPremiumVoucher } from 'shared/javascripts/utils/tracker';
import RevampVoucherMobile from 'shared/javascripts/components/RevampVoucherMobile';
import InfiniteLoading from 'vue-infinite-loading';
import MerchantPageChatFollow from '../../components/MerchantPageChatFollow';
import MerchantPageHighlights from '../../components/MerchantPageHighlights';
import MerchantPageHeaderInfo from '../../components/MerchantPageHeaderInfo';
import MerchantPageBanner from '../../components/MerchantPageBanner';
import MerchantPageVoucher from '../../components/MerchantPageVoucher';
import MerchantPageProductList from '../../components/MerchantPageProductList';
import MerchantPageProductListLoader from '../../components/MerchantPageProductListLoader';
import MerchantPageActionButton from '../../components/MerchantPageActionButton';
import MerchantPageIllustration from '../../components/MerchantPageIllustration';
import MerchantPageHeaderInfoLoading from '../../components/MerchantPageHeaderInfoLoading';

const BlSheetMv = () =>
  import(/* webpackChunkName: "BlSheetMv-merchantpage" */ '@bukalapak/bazaar-mweb/dist/components/BlSheetMv');

export default {
  name: 'MerchantPageIndex',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlButtonAv,
    BlSheetMv,
    MerchantPageChatFollow,
    MerchantPageBanner,
    MerchantPageHeaderInfo,
    RevampVoucherMobile,
    MerchantPageVoucher,
    MerchantPageHighlights,
    MerchantPageProductList,
    MerchantPageProductListLoader,
    MerchantPageActionButton,
    MerchantPageIllustration,
    InfiniteLoading,
    MerchantPageHeaderInfoLoading,
  },
  mixins: [AssetsMixin, StoreStateMixin],
  data() {
    return {
      openFeedbackInfo: false,
      openDeliveryInfo: false,
      openPremiumInfo: false,
      openTopSellerInfo: false,
      openVoucherModal: false,
      fixedFilter: false,
      limit: 12,
      infiniteId: 'product-list-index',
    };
  },
  computed: {
    ...mapState('store', ['store', 'isStoreLoading', 'products', 'vouchers', 'productsMeta', 'isProductsLoading']),
    ...mapGetters('store', ['isStoreInactive']),
    ...mapState('toggleFeatures', ['toggle']),
    totalFeedback() {
      if (!this.store.reviews) {
        return '';
      }
      return this.store.reviews.negative + this.store.reviews.positive;
    },
    feedbackPercentage() {
      if (!this.store.reviews) {
        return '';
      }
      const percentage =
        this.totalFeedback === 0 ? 0 : Math.round((this.store.reviews.positive / this.totalFeedback) * 100);
      return percentage;
    },
    isResultPageActive() {
      return this.$parent.isResultPageActive;
    },
    hasMoreData() {
      return this.products.length < this.productsMeta.total;
    },
    isSuperSellerActive() {
      return this.toggle['premium/super_seller'];
    },
    topSellerSheet() {
      return this.isSuperSellerActive
        ? {
            title: 'Super Seller',
            illus: 'ill_superSeller',
            text:
              'Badge ini muncul ketika pelapak yang mengaktifkan program Super Seller mencapai performa lapak sangat baik.',
          }
        : {
            title: 'Pelapak Terbaik',
            illus: 'ill_topSeller',
            text:
              'Badge ini hanya didapatkan oleh pelapak Premium yang berhasil mencapai score tertentu pada peforma lapaknya.',
          };
    },
  },
  methods: {
    async loadMoreProduct($state) {
      if (this.hasMoreData) {
        await this.$store.dispatch('store/UPDATE_FILTERS', {
          offset: this.products.length,
        });
        await this.$store.dispatch('store/GET_PRODUCTS', true);
        $state.loaded();
      } else {
        $state.completed();
      }
    },
    sendTracker(voucher) {
      trackPremiumVoucher({
        code: voucher.voucher_code,
        voucherType: voucher.premium_voucher_type,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.spica {
  &__illustration {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  &-bl-avatar {
    width: 68px;
    height: 68px;
    top: -48px;
    border: 4px solid white;
  }
  &-filter {
    position: sticky;
    top: 56px;
    z-index: 100;
  }
  &__product-list {
    &-height {
      min-height: calc(100vh - 110px);
    }
    &-height--no-product {
      min-height: calc(100vh - 270px);
    }
  }
  &-super-seller-illus {
    max-width: 252px;
  }
}
</style>
