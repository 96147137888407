/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_chat: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5V19a1 1 0 0 0 1.5.87L8 17h11.5a1.5 1.5 0 0 0 1.5-1.5v-11A1.5 1.5 0 0 0 19.5 3zM8 11a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm4 0a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm4 0a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"/>',
  },
});
