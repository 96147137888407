/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_cart: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M9 20a2 2 0 1 1-2-2 2 2 0 0 1 2 2zm9-7a1 1 0 0 0 .92-.61l3-7A1 1 0 0 0 21 4H6.39L6 2.68A1 1 0 0 0 5 2H2v2h2l.34.17 2.48 7.45-1.6 3.21a1.49 1.49 0 0 0 .06 1.46 1.52 1.52 0 0 0 1.28.71H20v-2H7.37l1-2zm-1 5a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"/>',
  },
});
