/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_menu: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21.5 7h-19a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h19a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5zm.5 5.5v-1a.5.5 0 0 0-.5-.5h-19a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5zm0 6v-1a.5.5 0 0 0-.5-.5h-19a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5z"/>',
  },
});
