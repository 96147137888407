/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_heart: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.4 4.63a5.63 5.63 0 0 1 0 7.86L12 21l-8.4-8.51a5.63 5.63 0 0 1 0-7.86 5.43 5.43 0 0 1 7.75 0 5.35 5.35 0 0 1 .65.79 5.35 5.35 0 0 1 .65-.79 5.43 5.43 0 0 1 7.75 0z"/>',
  },
});
