/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_email: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M2 5.41A1.5 1.5 0 0 1 3.5 4h17A1.5 1.5 0 0 1 22 5.41l-10 5.45zm10.47 7.47a1 1 0 0 1-1 0L2 7.68V18.5A1.5 1.5 0 0 0 3.5 20h17a1.5 1.5 0 0 0 1.5-1.5V7.68z"/>',
  },
});
