<template>
  <bl-flex-container-mv class="u-bg--white" direction="column" align-content="flex-start">
    <bl-flex-item-av class="u-pad-bottom--4 u-txt--bold" grow="1">
      <bl-text-av size="14" weight="bold">Statistik</bl-text-av>
    </bl-flex-item-av>
    <bl-flex-item-av grow="1">
      <table class="u-width-1 u-txt--base">
        <tr class="ut-statistic-delivery-time">
          <td><bl-text-av size="12">Waktu Kirim</bl-text-av></td>
          <td>:</td>
          <td>
            <bl-text-av size="12" weight="bold" class="u-mrgn-right--1 u-fg--red-brand u-display-inline">{{
              store.delivery_time
            }}</bl-text-av>
            <bl-tooltip-mv
              text="Waktu rata rata pelapak untuk mengirim paket ke pihak ekspedisi"
              placement="bottom-center"
            >
              <span class="c-m-icon c-m-icon--help-fill u-fg--ash" />
            </bl-tooltip-mv>
          </td>
        </tr>
        <tr class="ut-statistic-transactions">
          <td><bl-text-av size="12">Pesanan diterima</bl-text-av></td>
          <td>:</td>
          <td>
            <bl-text-av size="12" weight="bold">{{
              `${getTotalTransactionWording} (${feedbackPercentage}%)`
            }}</bl-text-av>
          </td>
        </tr>
        <tr class="ut-statistic-last-login">
          <td><bl-text-av size="12">Login terakhir</bl-text-av></td>
          <td>:</td>
          <td>
            <bl-text-av size="12">{{ lastAppearAtWording }}</bl-text-av>
          </td>
        </tr>
        <tr class="ut-statistic-join" v-if="!isFlagship">
          <td><bl-text-av size="12">Bergabung</bl-text-av></td>
          <td>:</td>
          <td>
            <bl-text-av size="12">{{ joinedAt }}</bl-text-av>
          </td>
        </tr>
      </table>
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import { mapState } from 'vuex';
import { BlFlexContainerMv, BlFlexItemAv, BlTooltipMv, BlTextAv } from '@bukalapak/bazaar-mweb';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import StoreStatisticsMixin from 'shared/javascripts/mixins/store/statistics';

export default {
  name: 'MerchantPageStatistics',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTooltipMv,
    BlTextAv,
  },
  props: {
    isFlagship: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [StoreStateMixin, StoreStatisticsMixin],
  computed: {
    ...mapState('store', ['store']),
    getTotalTransactionWording() {
      const total = this.store.rejection.recent_transactions;
      const rejectedTransation = this.store.rejection.rejected;
      return !rejectedTransation
        ? 'Tidak pernah menolak pesanan'
        : `Menerima ${total - rejectedTransation} dari ${total} pesanan`;
    },
  },
};
</script>
<style lang="scss" scope>
td {
  padding-bottom: 8px;
}
td:nth-child(1) {
  padding-right: 12px;
}
td:nth-child(2) {
  padding-right: 18px;
}
</style>
