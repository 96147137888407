<template>
  <div class="spica-header-info u-position-relative">
    <bl-flex-container-mv
      class="u-margin-top--0 u-bg--white u-border-radius--13"
      align-content="flex-start"
      padding="12px 16px"
    >
      <bl-flex-item-av class="spica-bl-avatar-container">
        <bl-avatar-av class="spica-bl-avatar" size="large">
          <img :src="avatarUrl" :alt="storeName" />
        </bl-avatar-av>
      </bl-flex-item-av>
      <bl-flex-item-av class="u-mrgn-left--4" grow="1" shrink="1">
        <bl-flex-container-mv direction="column" align-content="flex-start">
          <bl-flex-item-av class="u-txt--medium u-txt--bold">
            <bl-flex-container-mv align-content="flex-start">
              <bl-flex-item-av grow="1" shrink="1">
                <span class="u-valign-middle">{{ storeName }}</span>
                <span
                  v-if="store.premium_level !== 'none' && !store.premium_top_seller && !isSuperSellerActive"
                  @click="$emit('open-premium-info')"
                  class="u-pointer c-seller-badge u-mrgn-right--1 c-seller-badge--premium c-seller-badge--small"
                />
              </bl-flex-item-av>
              <bl-flex-item-av v-if="store.brand_seller">
                <img
                  class="u-position-relative ut-bukamall-logo"
                  :src="BUKAMALL_LOGO_URL"
                  style="height: 26px; top: -3px;"
                />
              </bl-flex-item-av>
              <bl-flex-item-av v-else-if="showSuperSellerBadge">
                <div @click="$emit('open-top-seller-info')">
                  <template v-if="isSuperSellerActive && isSuperSellerRebrandingEnabled">
                    <div class="ic-new_superseller u-mrgn-left--1 ut-premium-label" />
                  </template>
                  <template v-else-if="isSuperSellerActive">
                    <div class="c-label c-label--super-seller u-mrgn-left--1 ut-premium-label">Super Seller</div>
                  </template>
                  <template v-else>
                    <span class="c-seller-badge c-seller-badge--premium c-seller-badge--small u-valign-middle" />
                    <span class="c-label c-label--lapak-terbaik u-txt--uppercase ut-premium-label">
                      Terbaik
                    </span>
                  </template>
                </div>
              </bl-flex-item-av>
            </bl-flex-container-mv>
          </bl-flex-item-av>
          <bl-flex-item-av class="u-txt--fair u-fg--ash-dark u-mrgn-bottom--2">
            {{ store.address.city }}
          </bl-flex-item-av>
        </bl-flex-container-mv>
      </bl-flex-item-av>
    </bl-flex-container-mv>
    <bl-flex-container-mv class="u-margin-top--0 u-bg--white u-txt--center" padding="0px 16px 12px">
      <bl-flex-item-av grow="1">
        <div class="u-pointer" @click="$emit('open-feedback-info')">
          <div>
            <bl-icon-av class="u-valign-bottom u-mrgn-right--1" name="ico_thumbsup" color="active-darker" />{{
              feedbackPercentage
            }}%
          </div>
          <div class="u-fg--ash-dark u-mrgn-top--1">{{ feedback }} Feedback</div>
        </div>
      </bl-flex-item-av>
      <bl-flex-item-av grow="1">
        <div class="u-pointer" @click="$emit('open-delivery-info')">
          <div>
            <bl-icon-av class="u-valign-middle" name="ico_time_minor" color="active-darker" />
            {{ store.delivery_time }}
          </div>
          <div class="u-fg--ash-dark u-mrgn-top--1">Waktu Proses</div>
        </div>
      </bl-flex-item-av>
      <bl-flex-item-av grow="1">
        <div>
          <template v-if="onlineStatus">
            <bl-badge-av status="online" text="Online" />
          </template>
          <template v-else>
            {{ lastAppearAtWording }}
          </template>
        </div>
        <div class="u-fg--ash-dark u-mrgn-top--1">Terakhir Online</div>
      </bl-flex-item-av>
    </bl-flex-container-mv>
  </div>
</template>

<script>
import { simpleMoney } from 'shared/javascripts/utils/number-helper';
import BlIconAv from 'shared/javascripts/components/BlIconAv';
import BukamallMixin from 'shared/javascripts/mixins/bukamall';
import StoreStatisticsMixin from 'shared/javascripts/mixins/store/statistics';
import { BlFlexContainerMv, BlFlexItemAv, BlAvatarAv, BlBadgeAv } from '@bukalapak/bazaar-mweb';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageHeaderInfo',
  mixins: [BukamallMixin, StoreStatisticsMixin],
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlAvatarAv,
    BlIconAv,
    BlBadgeAv,
  },
  data() {
    return {
      detailInfo: false,
    };
  },
  computed: {
    ...mapState('store', ['brand']),
    ...mapState('toggleFeatures', ['toggle']),
    ...mapState('chat', ['onlineStatus']),
    avatarUrl() {
      return this.brand.logo_url || this.store.avatar_url;
    },
    storeName() {
      return this.brand.name || this.store.name;
    },
    isSuperSellerActive() {
      return this.toggle['premium/super_seller'];
    },
    isSuperSellerRebrandingEnabled() {
      return this.toggle['super-seller-rebranding-enabled'];
    },
    showSuperSellerBadge() {
      return this.store.premium_top_seller && this.isSuperSellerRebrandingEnabled !== undefined;
    },
    feedback() {
      return simpleMoney(this.totalFeedback, '').replace('rb', 'K');
    },
  },
};
</script>

<style src="./MerchantPageHeaderInfo.style.scss" lang="scss" scoped></style>
