/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_printer: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.5 8h-17A1.5 1.5 0 0 0 2 9.5v7A1.5 1.5 0 0 0 3.5 18h.11l-.34 1a1.53 1.53 0 0 0 .21 1.36 1.49 1.49 0 0 0 1.21.64h14.62a1.49 1.49 0 0 0 1.21-.62 1.51 1.51 0 0 0 .21-1.38l-.34-1h.11a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 20.5 8zM18 10h1a1 1 0 0 1 0 2h-1a1 1 0 0 1 0-2zm.61 9H5.39l.33-1 1-3h10.56l1 3zM19 6H5V4.5A1.5 1.5 0 0 1 6.5 3h11A1.5 1.5 0 0 1 19 4.5z"/>',
  },
});
