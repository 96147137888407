<template>
  <section>
    <merchant-page-action-button
      class="spica__filter--fixed"
      @open-label-sheet="$emit('open-label-sheet')"
      @open-sort-sheet="$emit('open-sort-sheet')"
      @open-filter-sheet="$emit('open-filter-sheet')"
    />
    <bl-flex-container-mv justify-content="flex-start" padding="0px 8px 18px">
      <bl-flex-item-av class="spica__product-list u-position-relative u-width-1" grow="1">
        <merchant-page-product-list-loader v-if="isProductsLoading" />
        <template v-else>
          <merchant-page-product-list />
          <infinite-loading v-if="hasMoreData" @infinite="loadMoreProduct" :identifier="infiniteId">
            <div slot="spinner">
              <merchant-page-product-list-loader />
            </div>
          </infinite-loading>
        </template>
        <merchant-page-illustration />
      </bl-flex-item-av>
    </bl-flex-container-mv>
  </section>
</template>

<script>
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import AssetsMixin from 'shared/javascripts/mixins/assets';
import { mapState } from 'vuex';
import { BlFlexItemAv, BlFlexContainerMv } from '@bukalapak/bazaar-mweb';
import InfiniteLoading from 'vue-infinite-loading';
import MerchantPageActionButton from '../../components/MerchantPageActionButton';
import MerchantPageProductListLoader from '../../components/MerchantPageProductListLoader';
import MerchantPageProductList from '../../components/MerchantPageProductList';
import MerchantPageIllustration from '../../components/MerchantPageIllustration';

export default {
  name: 'MerchantPageSearchResult',
  components: {
    MerchantPageActionButton,
    MerchantPageProductListLoader,
    MerchantPageProductList,
    MerchantPageIllustration,
    BlFlexItemAv,
    BlFlexContainerMv,
    InfiniteLoading,
  },
  data() {
    return {
      infiniteId: 'product-list-search',
    };
  },
  mixins: [AssetsMixin, StoreStateMixin],
  computed: {
    ...mapState('store', ['products', 'productsMeta', 'isProductsLoading']),
    hasMoreData() {
      return this.products.length < this.productsMeta.total;
    },
  },
  methods: {
    async loadMoreProduct($state) {
      if (this.hasMoreData) {
        await this.$store.dispatch('store/UPDATE_FILTERS', {
          offset: this.products.length,
        });
        await this.$store.dispatch('store/GET_PRODUCTS', true);
        $state.loaded();
      } else {
        $state.completed();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.spica {
  &__illustration {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  &__filter--fixed {
    margin: 0px !important;
    position: sticky;
    top: 55px;
    width: 100%;
    z-index: 100;
  }
  &__product {
    &-list {
      min-height: calc(100vh - 136px);
    }
  }
}
</style>
