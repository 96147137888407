/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_cross_circle: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4.71 13.29a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0L12 13.41l-3.29 3.3a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42l3.3-3.29-3.3-3.29a1 1 0 1 1 1.42-1.42l3.29 3.3 3.29-3.3a1 1 0 1 1 1.42 1.42L13.41 12z"/>',
  },
});
