/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_notification: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21 17a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2h1V9.5A5.49 5.49 0 0 1 10 4a2 2 0 0 1 4 0 5.49 5.49 0 0 1 5 5.5V16h1a1 1 0 0 1 1 1zm-9 5a3 3 0 0 0 3-2.5H9.05A3 3 0 0 0 12 22z"/>',
  },
});
