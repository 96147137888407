/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_portfolio: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.5 3h-16A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h16a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 20.5 3zM9 18.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5zm5 0a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5zm5 0a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5z"/>',
  },
});
