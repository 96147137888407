/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_promoted_push: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M13.71 11.71L11 14.42v5.08A1.5 1.5 0 0 1 9.5 21h-5A1.5 1.5 0 0 1 3 19.5v-5A1.5 1.5 0 0 1 4.5 13h5.08l2.71-2.72-1.44-1.43a.5.5 0 0 1 .36-.85h4.29a.5.5 0 0 1 .5.5v4.29a.5.5 0 0 1-.85.36zM19.5 3h-13A1.5 1.5 0 0 0 5 4.5V9a1 1 0 0 0 2 0V5h12v12h-4a1 1 0 0 0 0 2h4.5a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 19.5 3z"/>',
  },
});
