import queryString from 'query-string';
import isURL from 'validator/lib/isURL';
import { removeEmpty } from './object-helpers';

/**
 * @param {any} fields
 * @returns {string}
 */
export const httpParamsBuilder = fields =>
  Object.keys(fields)
    .map(key => `${key}=${fields[key]}`)
    .join('&');

export const cleanUrlQuery = ({ urlQuery, removeEmptyString = false, removeEmptyArray = false }) =>
  queryString.stringify(removeEmpty({ object: urlQuery, removeEmptyString, removeEmptyArray }));

export const getUrlParameter = name => {
  // eslint-disable-next-line no-param-reassign, no-useless-escape
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const replaceComebackUrl = element => {
  if (element && element.href) {
    const link = element.href.replace(
      '%2F_internal%2Flayouts%2Fmerchant_page',
      encodeURIComponent(window.location.href),
    );
    element.href = link;
  }
};

export const isValidUrl = url => isURL((url || '').toString(), { require_protocol: true });
