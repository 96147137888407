<template>
  <bl-flex-container-mv class="u-bg--white" direction="column" align-content="flex-start">
    <bl-flex-item-av class="u-pad-bottom--4" grow="1">
      <bl-text-av size="14" weight="bold">Deskripsi Lapak</bl-text-av>
    </bl-flex-item-av>
    <bl-flex-item-av grow="1">
      <bl-text-av size="12">{{ store.description }}</bl-text-av>
    </bl-flex-item-av>
  </bl-flex-container-mv>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv, BlTextAv } from '@bukalapak/bazaar-mweb';
import { mapState } from 'vuex';

export default {
  name: 'MerchantPageDescription',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
  },
  computed: {
    ...mapState('store', ['store']),
  },
};
</script>
