/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_camera: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.5 5H17l-.59-1.17A1.49 1.49 0 0 0 15.07 3H8.93a1.49 1.49 0 0 0-1.34.83L7 5H3.5A1.5 1.5 0 0 0 2 6.5v12A1.5 1.5 0 0 0 3.5 20h17a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 20.5 5zM12 17a5 5 0 1 1 5-5 5 5 0 0 1-5 5zm3-5a3 3 0 1 1-3-3 3 3 0 0 1 3 3z"/>',
  },
});
