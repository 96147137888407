/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_more_vertical: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M14 5a2 2 0 1 1-2-2 2 2 0 0 1 2 2zm-2 5a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 7a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"/>',
  },
});
