import chat from './modules/chat';
import store from './modules/store';
import productHighlights from './modules/product-highlights';
import onboarding from './modules/onboarding';
import toggleFeatures from './modules/toggle-features';

const { Vuex } = global;
export const createStore = (options = {}) =>
  new Vuex.Store({
    strict: process.env.NODE_ENV === 'development',
    modules: {
      chat,
      store,
      onboarding,
      productHighlights,
      toggleFeatures,
    },
    ...options,
  });
const merchantPageStore = createStore();
export default merchantPageStore;
