/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_thumbsdown: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M6 15H4a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1zm16-1.77l-1.46-8A1.5 1.5 0 0 0 19.05 4H11a2 2 0 0 0-2 2v8.29a1 1 0 0 0 .52.88 8.1 8.1 0 0 1 3.41 5 1 1 0 0 0 1 .83h.48a1 1 0 0 0 1-.7A11 11 0 0 0 15 15h5.5a1.51 1.51 0 0 0 1.5-1.77z"/>',
  },
});
