<template>
  <div class="c-link-list filter-rating">
    <div v-for="i in ratingKeys" :key="`rating-${i}-${renderKey}`" class="c-link-list__item u-pad-v--4">
      <bl-checkbox-av v-model="productRatingData" :native-value="i" :disabled="i === productRatingData[0]">
        <span class="u-txt--large">
          <div class="c-rating">
            <div class="c-rating__bg">
              <span v-for="n in i" :key="`star-${n}`" class="c-rating__unit c-icon c-icon--star" />
            </div>
            <div class="c-rating__fg" :style="{ width: '100%' }">
              <span v-for="n in i" :key="`star-${n}`" class="c-rating__unit c-icon c-icon--star" />
            </div>
          </div>
        </span>
      </bl-checkbox-av>
    </div>
    <div class="c-link-list__item u-pad-v--4">
      <bl-checkbox-av v-model="allRating" :disabled="allRating" :native-value="true">Semua rating</bl-checkbox-av>
    </div>
  </div>
</template>

<script>
import { BlCheckboxAv } from '@bukalapak/bazaar-mweb';

export default {
  name: 'FilterRating',
  components: {
    BlCheckboxAv,
  },
  props: {
    productRating: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      renderKey: 1,
      ratingKeys: [5, 4, 3, 2, 1],
      allRating: !(this.productRating.split(':').length > 1),
    };
  },
  computed: {
    productRatingData: {
      get() {
        const rating = this.productRating.split(':');
        const productRating = [];
        if (rating.length > 1) {
          for (let i = parseInt(rating[0], 10); i <= 5; i += 1) {
            productRating.push(i);
          }
        }
        return productRating;
      },
      set(val) {
        this.renderKey += 1;
        if (val.length) {
          const idx = parseInt(val[val.length - 1], 10);
          const diffValue = this.productRatingData.filter(e => val.indexOf(e) < 0);
          const rating = diffValue.length ? diffValue[0] : idx;
          this.$emit('product-rating-change', `${rating}:5`);
          this.allRating = false;
        } else {
          this.allRating = true;
        }
      },
    },
  },
  watch: {
    allRating(val) {
      if (val) {
        this.productRatingData = [];
        this.$emit('product-rating-change', '');
      }
    },
  },
};
</script>
<style lang="scss">
.bl-checkbox input[type='checkbox']:checked + .bl-checkbox__check {
  background-color: #ea5164 !important;
  border-color: #ea5164 !important;
}
</style>
