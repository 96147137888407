<template>
  <BlNotificationMv
    class="spica-notification"
    :text="text"
    :show="showNotification"
    :state="state"
    :action="action"
    @click="callback"
  />
</template>

<script>
import { setTimeout } from 'timers';
import BlNotificationMv from './BlNotificationMv';
import BlFlashMessageEventBus from './BlFlashMessageEventBus';

export default {
  name: 'BlFlashMessage',
  components: {
    BlNotificationMv,
  },
  data() {
    return {
      showNotification: false,
      text: '',
      state: 'success',
      action: '',
      callback: () => {},
    };
  },
  beforeMount() {
    BlFlashMessageEventBus.$on('show', (text, state, action, callback) => {
      this.showNotification = true;
      this.text = text;
      this.state = state;
      this.action = action;
      this.callback = callback;
      setTimeout(() => {
        this.showNotification = false;
      }, 3000);
    });
  },
};
</script>

<style lang="scss" scoped>
.spica-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>
