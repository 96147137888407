/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_call_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M18 13l-1 3.48a2.36 2.36 0 0 1-1.26 1.3 2.29 2.29 0 0 1-1.78 0 8.11 8.11 0 0 0-.79-.29 14.58 14.58 0 0 1-6.23-4A18 18 0 0 1 2.59 7c-.13-.31-.25-.59-.37-.85a2.35 2.35 0 0 1-.06-1.84A2.33 2.33 0 0 1 3.48 3h.13l3.31-1a.2.2 0 0 1 .24.13L8.62 7a.19.19 0 0 1 0 .19L6.86 8.85A17.8 17.8 0 0 0 9 11.45a17.45 17.45 0 0 0 2 1.76l1.8-1.79a.2.2 0 0 1 .19-.05l4.82 1.43a.2.2 0 0 1 .19.2z"/>',
  },
});
