/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_sort_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M17 6H3a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2zm-1 4a1 1 0 0 0-1-1H5a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1zm-2 5a1 1 0 0 0-1-1H7a1 1 0 0 0 0 2h6a1 1 0 0 0 1-1z"/>',
  },
});
