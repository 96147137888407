<template>
  <div class="revamp-c-card--head u-overflow--hidden u-position-relative">
    <a :title="product.name" :href="productUrl" rel="nofollow">
      <div class="ratio-container">
        <WebP :src="imageProduct" webp>
          <bl-image class="ratio-img c-card__img lazyload" :data-src="imageProduct" :alt="product.name" />
        </WebP>
      </div>
    </a>
    <template v-if="hasDeal">
      <bl-ribbon-av type="diskon"> {{ product.deal.percentage }}% </bl-ribbon-av>
    </template>
    <template v-if="product.condition === 'Bekas'">
      <bl-ribbon-av type="netral">
        Bekas
      </bl-ribbon-av>
    </template>
    <template v-if="product.wholesales.length > 0">
      <bl-ribbon-av type="grosir">
        Grosir
      </bl-ribbon-av>
    </template>
  </div>
</template>

<script>
import { BlRibbonAv } from '@bukalapak/bazaar-mweb';
import BlImage from '../../BlImage';
import WebP from '../../WebP';
import { DEFAULT_IMAGE_PRODUCT } from '../../../utils/images';

export default {
  name: 'ProductCardHead',
  components: {
    BlRibbonAv,
    BlImage,
    WebP,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
    query: {
      type: String,
      default: '',
    },
  },
  computed: {
    imageProduct() {
      const { product } = this;
      const imageProduct =
        product.images.large_urls && product.images.large_urls.length
          ? product.images.large_urls[0]
          : DEFAULT_IMAGE_PRODUCT;
      return imageProduct.replace('/large/', '/w-200/');
    },
    productUrl() {
      return this.query ? `${this.product.url}?${this.query}` : this.product.url;
    },
    hasDeal() {
      const { deal } = this.product;
      if (deal && deal.applied_date && deal.expired_date) {
        const validAppliedDate = Date.parse(deal.applied_date) <= Date.now();
        const validExpiredDate = Date.parse(deal.expired_date) > Date.now();
        return validAppliedDate && validExpiredDate;
      }
      return false;
    },
  },
};
</script>

<style src="./RevampProductCardHead.style.scss" lang="scss" scoped></style>
