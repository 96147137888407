<template>
  <div class="product-list">
    <bl-flex-container-mv
      direction="row"
      alignItems="center"
      class="u-mrgn-bottom--2"
      v-for="(product, idx) in coupledProducts"
      :key="idx"
    >
      <bl-flex-item-av basis="0" shrink="1" grow="1" margin="0 8px 0 0">
        <merchant-page-product-card :product="product[0]" />
      </bl-flex-item-av>
      <bl-flex-item-av basis="0" shrink="1" grow="1">
        <merchant-page-product-card v-if="product.length > 1" :product="product[1]" />
        <revamp-product-card-mobile-loader v-else :isOwnProduct="isCurrentUserStore" class="u-visibility-hidden" />
      </bl-flex-item-av>
    </bl-flex-container-mv>
  </div>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import { mapState } from 'vuex';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';
import RevampProductCardMobileLoader from 'shared/javascripts/components/RevampProductCardMobileLoader';
import MerchantPageProductCard from './MerchantPageProductCard.vue';

export default {
  name: 'MerchantPageProductList',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    MerchantPageProductCard,
    RevampProductCardMobileLoader,
  },
  mixins: [StoreStateMixin],
  data() {
    return {
      isPWABukaGames: false,
    };
  },
  computed: {
    ...mapState('store', ['products']),
    filteredProducts() {
      return this.products.map(product => {
        product.url = product.url.replace('www.', 'm.');
        return product;
      });
    },
    coupledProducts() {
      const products = this.isPWABukaGames ? this.filteredProducts : this.products;
      const newProductArray = [];
      const tempProducts = JSON.parse(JSON.stringify(products));
      while (tempProducts.length) {
        newProductArray.push(tempProducts.splice(0, 2));
      }
      return newProductArray;
    },
  },
  mounted() {
    this.getPWAGamesState();
  },
  methods: {
    getPWAGamesState() {
      this.isPWABukaGames = typeof window !== 'undefined' && window.sessionStorage.getItem('isPWABukaGames') === 'true';
    },
  },
};
</script>
