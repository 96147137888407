<template>
  <div>
    <div
      v-for="(sort, idx) in sorts"
      :key="sort.name"
      :class="`${idx + 1 === sorts.length ? 'u-mrgn-bottom--2' : 'u-mrgn-bottom--6'} ut-page-sort-item-${idx}`"
      @click="changeSorting(sort)"
    >
      <span :class="{ 'u-txt--bold': filters.sort === sort.value }">{{ sort.name }}</span>
      <bl-icon-av
        v-if="filters.sort === sort.value"
        class="u-float-right u-valign-top"
        name="ico_check_minor"
        color="crimson"
      />
    </div>
  </div>
</template>

<script>
import BlIconAv from 'shared/javascripts/components/BlIconAv';
import { mapState } from 'vuex';
import sortData from '../../data/sorts.json';

export default {
  name: 'MerchantPageSort',
  components: {
    BlIconAv,
  },
  computed: {
    ...mapState('store', ['filters']),
    sorts() {
      return sortData.filter(sort => sort.value !== '' || (!!this.filters.keywords && sort.value === ''));
    },
    chosenSort() {
      return this.sorts.find(sort => this.filters.sort === sort.value);
    },
  },
  methods: {
    async changeSorting(sort) {
      await this.$store.dispatch('store/UPDATE_FILTERS', { sort: sort.value, offset: 0 });
      this.$emit('apply-filter');
    },
  },
};
</script>
