/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_scan_barcode: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M7 6h10a1 1 0 0 1 1 1v3H6V7a1 1 0 0 1 1-1zM6 20H4v-2a1 1 0 0 0-2 0v3a1 1 0 0 0 1 1h3a1 1 0 0 0 0-2zM3 7a1 1 0 0 0 1-1V4h2a1 1 0 0 0 0-2H3a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1zm18-5h-3a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0V3a1 1 0 0 0-1-1zm-3 15v-3h1.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H6v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1zm3 0a1 1 0 0 0-1 1v2h-2a1 1 0 0 0 0 2h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>',
  },
});
