/* eslint-disable no-undef */
import { getUrl, wrap } from '../../utils/api-helpers';
import { setOptions } from '../../utils/secure-client';

const PREFIX_URL = getUrl('/chat');

const interfaces = {
  /**
   * Retrieve Last Seen
   */
  retrieveLastSeen(partnerId) {
    return this.get(`${PREFIX_URL}/last-seen/${partnerId}`, 'store', setOptions());
  },
  retrieveOnlineStatus(userIds) {
    return this.get(`${PREFIX_URL}/onlines`, 'store', {
      ...setOptions(),
      params: {
        user_ids: userIds,
      },
    });
  },
};

export default wrap(interfaces, 'xhrChat');
