/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_camera_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M16.5 4h-2l-1.2-1.6a1 1 0 0 0-.8-.4h-5a1 1 0 0 0-.8.4L5.5 4h-2A1.5 1.5 0 0 0 2 5.5v10A1.5 1.5 0 0 0 3.5 17h13a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 16.5 4zM10 13a3 3 0 1 1 3-3 3 3 0 0 1-3 3z"/>',
  },
});
