/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_voucher: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21.5 10.12a.5.5 0 0 0 .5-.5v-4a1.5 1.5 0 0 0-1.5-1.5h-17A1.5 1.5 0 0 0 2 5.62v4a.5.5 0 0 0 .5.5 1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5.5.5 0 0 0-.5.5v4a1.5 1.5 0 0 0 1.5 1.5h17a1.5 1.5 0 0 0 1.5-1.5v-4a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1-1.5-1.5v-1a1.5 1.5 0 0 1 1.5-1.5zM7 9.62a1.45 1.45 0 0 1 1.4-1.5 1.5 1.5 0 0 1 0 3A1.46 1.46 0 0 1 7 9.62zm1.06 6.5l6-8h1.88l-6 8zm7.54 0a1.5 1.5 0 1 1 1.4-1.5 1.46 1.46 0 0 1-1.4 1.5z"/>',
  },
});
