/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_notification_off_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M7.18 16h5.64a3 3 0 0 1-5.64 0zM15 7a4 4 0 0 0-4-4V2H9v1a4 4 0 0 0-1.67.37L15 10.14zm3.18 8.14L3.89 2.84l-1.3 1.52L5 6.47A4.55 4.55 0 0 0 5 7v5H4a1 1 0 0 0 0 2h9.79l3.09 2.66z"/>',
  },
});
