/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_setting: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M20.36 13.45a7.58 7.58 0 0 0 0-2.9L21.86 9a.5.5 0 0 0 .07-.59l-1.62-2.82a.5.5 0 0 0-.55-.23l-2.11.53a7.71 7.71 0 0 0-2.44-1.41l-.61-2.12a.5.5 0 0 0-.48-.36h-3.24a.5.5 0 0 0-.48.36l-.61 2.12a7.71 7.71 0 0 0-2.44 1.41l-2.11-.53a.5.5 0 0 0-.55.23L3.07 8.41a.5.5 0 0 0 .07.59l1.5 1.55a7.58 7.58 0 0 0 0 2.9L3.14 15a.5.5 0 0 0-.07.59l1.62 2.82a.5.5 0 0 0 .55.23l2.11-.53a7.71 7.71 0 0 0 2.44 1.41l.61 2.12a.5.5 0 0 0 .48.36h3.24a.5.5 0 0 0 .48-.36l.61-2.12a7.71 7.71 0 0 0 2.44-1.41l2.11.53a.5.5 0 0 0 .55-.23l1.62-2.82a.5.5 0 0 0-.07-.59zM12.5 16a4 4 0 1 1 4-4 4 4 0 0 1-4 4z"/>',
  },
});
