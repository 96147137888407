<template>
  <bl-card-mv>
    <div class="revamp-product-loader">
      <bl-shimmer-av width="100%" class="u-border-radius--2 revamp-product-loader-header" />
      <div class="u-pad--3">
        <bl-shimmer-av class="u-txt--normal u-mrgn-bottom--1 u-border-radius--2" width="100%" height="40px" />
        <bl-shimmer-av class="u-txt--normal u-border-radius--2 u-mrgn-bottom--1" width="100%" />
        <bl-shimmer-av class="u-txt--normal u-border-radius--2" width="100%" />
        <bl-shimmer-av
          v-if="!isOwnProduct"
          class="u-txt--normal u-border-radius--2 u-mrgn-top--2 revamp-product-loader-btn"
          width="100%"
          height="36px"
        />
      </div>
    </div>
  </bl-card-mv>
</template>

<script>
import { BlCardMv, BlShimmerAv } from '@bukalapak/bazaar-mweb';

export default {
  name: 'RevampProductCardMobileLoader',
  components: {
    BlShimmerAv,
    BlCardMv,
  },
  props: {
    isOwnProduct: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style src="./RevampProductCardMobileLoader.style.scss" lang="scss"></style>
