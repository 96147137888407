/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_notification_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M7.18 16h5.64a3 3 0 0 1-5.64 0zM16 12h-1V7a4 4 0 0 0-4-4V2H9v1a4 4 0 0 0-4 4v5H4a1 1 0 0 0 0 2h12a1 1 0 0 0 0-2z"/>',
  },
});
