/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_discussion: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M22 8.5V21a1 1 0 0 1-1.6.8L18 20H8.5A1.5 1.5 0 0 1 7 18.5V17h11a1 1 0 0 0 1-1V7h1.5A1.5 1.5 0 0 1 22 8.5zm-5 5v-10A1.5 1.5 0 0 0 15.5 2h-12A1.5 1.5 0 0 0 2 3.5V16a1 1 0 0 0 1.6.8L6 15h9.5a1.5 1.5 0 0 0 1.5-1.5z"/>',
  },
});
