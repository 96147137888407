import HoneyBagderNotify, { errorHandler } from 'plugins/honeybadger';
import BlFlashMessagePlugin from 'plugins/BlFlashMessage';

import { money, simpleMoney } from 'shared/javascripts/utils/number-helper';
import createPersistedState from 'vuex-persistedstate';
import 'lazysizes';

import { createStore } from 'shared/javascripts/stores/merchant-mweb';
import routes from './routes';

import MerchantPageApp from './App.vue';

const MAX_PERSISTED_PRODUCTS = 120;
const persistedStateStorePlugin = createPersistedState({
  key: 'bl_merchant_page_mweb',
  paths: ['store.persistedStoreUrl', 'store.products', 'store.productsMeta', 'store.isProductsLoading'],
  storage: window.sessionStorage,
  setState: (key, data, storage) => {
    const { products } = data.store;
    storage.setItem(
      key,
      JSON.stringify({
        ...data,
        store: {
          ...data.store,
          // limit persisted products
          products: products.slice(0, MAX_PERSISTED_PRODUCTS),
        },
      }),
    );
  },
});

const store = createStore({
  plugins: [persistedStateStorePlugin],
});
const { Vue } = global;
const APP_ID = '#merchant-page';

Vue.use(BlFlashMessagePlugin);
Vue.use(HoneyBagderNotify);
Vue.filter('money', value => money(value));
Vue.filter('simpleMoney', value => simpleMoney(value));

Vue.config.errorHandler = (err, vm, info) => {
  errorHandler({
    err,
    component: vm.name,
    action: '',
    fingerprint: 'spica/merchant_page_westeros_mweb',
    context: info,
  });
};

async function initializePage() {
  let router = null;
  if (window.location.origin.includes(process.env.BUKALAPAK_HOSTNAME)) {
    const VueRouter = (await import('vue-router')).default;
    router = new VueRouter({
      routes,
      mode: 'history',
    });
  } else {
    const ManualRouterPlugin = (await import('plugins/manual-router')).default;
    Vue.use(ManualRouterPlugin);
  }

  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el: APP_ID,
    components: {
      MerchantPageApp,
    },
    store,
    router,
    methods: {
      notifyError(err, action, payload, component) {
        this.$hbNotify({
          err,
          component,
          action,
          context: { payload },
          fingerprint: 'spica/merchant_page_westeros_mweb',
        });
      },
    },
  });
}

const { history } = window;
if (history && history.scrollRestoration && history.scrollRestoration === 'manual') {
  window.history.scrollRestoration = 'auto';
}

initializePage();
