/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_shipping: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21.56 10.15l-2.85-2.86A1 1 0 0 0 18 7h-3v-.5A1.5 1.5 0 0 0 13.5 5h-10A1.5 1.5 0 0 0 2 6.5v10a1.48 1.48 0 0 0 1 1.41V18a3 3 0 0 0 6 0h5a3 3 0 0 0 6 0h1a1 1 0 0 0 1-1v-5.79a1.5 1.5 0 0 0-.44-1.06zM6 19.5A1.5 1.5 0 1 1 7.5 18 1.5 1.5 0 0 1 6 19.5zm11 0a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5zm3-6.5h-5V9h2.59L20 11.41z"/>',
  },
});
