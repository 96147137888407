/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_external_link_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M17 4v5a1 1 0 0 1-2 0V6.41l-5.29 5.3a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42L13.59 5H11a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1zm-1 8a1 1 0 0 0-1 1v2H5V5h2a1 1 0 0 0 0-2H4.5A1.5 1.5 0 0 0 3 4.5v11A1.5 1.5 0 0 0 4.5 17h11a1.5 1.5 0 0 0 1.5-1.5V13a1 1 0 0 0-1-1z"/>',
  },
});
