/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_eticket: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data:
      '<path pid="0" d="M21.5 10a.5.5 0 0 0 .5-.5v-4A1.5 1.5 0 0 0 20.5 4H15v4h-2V4H3.5A1.5 1.5 0 0 0 2 5.5v4a.5.5 0 0 0 .5.5A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14a.5.5 0 0 0-.5.5v4A1.5 1.5 0 0 0 3.5 20H13v-4h2v4h5.5a1.5 1.5 0 0 0 1.5-1.5v-4a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1-1.5-1.5v-1a1.5 1.5 0 0 1 1.5-1.5zM15 14h-2v-4h2z"/>',
  },
});
