<template>
  <div class="product-list">
    <bl-flex-container-mv direction="row" alignItems="center" class="u-mrgn-bottom--2" v-for="idx in 2" :key="idx">
      <bl-flex-item-av basis="0" shrink="1" grow="1" margin="0 8px 0 0">
        <revamp-product-card-mobile-loader :is-own-product="isCurrentUserStore" />
      </bl-flex-item-av>
      <bl-flex-item-av basis="0" shrink="1" grow="1">
        <revamp-product-card-mobile-loader :is-own-product="isCurrentUserStore" />
      </bl-flex-item-av>
    </bl-flex-container-mv>
  </div>
</template>

<script>
import { BlFlexContainerMv, BlFlexItemAv } from '@bukalapak/bazaar-mweb';
import RevampProductCardMobileLoader from 'shared/javascripts/components/RevampProductCardMobileLoader';
import StoreStateMixin from 'shared/javascripts/mixins/merchant-page/state';

export default {
  name: 'MerchantPageProductListLoader',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    RevampProductCardMobileLoader,
  },
  mixins: [StoreStateMixin],
};
</script>
