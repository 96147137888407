import ToggleFeaturesAPI from 'shared/javascripts/api/toggle_features';

const LIST_TOGGLE = [
  'seller_center/feature/product_highlights',
  'premium/super_seller',
  'super-seller-rebranding-enabled',
];

export const initialState = () => ({
  toggle: {
    // super seller default true
    'premium/super_seller': true,
  },
});

const getters = {};

const mutations = {
  updateToggle: (state, payload) => {
    state.toggle = payload;
  },
};

const actions = {
  GET_ALL_TOGGLE: async ({ commit }) => {
    const { data } = await ToggleFeaturesAPI.getListToggle(LIST_TOGGLE);
    const toggleList = Object.keys(data);

    const transformedData = {};
    toggleList.forEach(toggle => {
      transformedData[toggle] = data[toggle].status;
    });
    commit('updateToggle', transformedData);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
