<template>
  <bl-card-mv :shadow="false">
    <div class="ratio-container u-mrgn-bottom--2">
      <WebP :src="imageProduct" webp>
        <bl-image class="ratio-img c-card__img lazyload" :data-src="imageProduct" :alt="product.name" />
      </WebP>
    </div>
    <div class="u-txt--ellipsis u-mrgn-bottom--1">{{ product.name }}</div>
    <template v-if="hasDeal">
      <div class="u-txt--normal u-txt--small u-txt-price--original">
        {{ product.original_price | money }}
      </div>
      <div class="u-txt-price u-txt-price--discounted">
        {{ product.price | money }}
      </div>
    </template>
    <div v-else class="u-pad-top--4 u-txt-price u-txt--fair">
      {{ product.price | money }}
    </div>
  </bl-card-mv>
</template>

<script>
import { BlCardMv } from '@bukalapak/bazaar-mweb';
import BlImage from '../BlImage';
import WebP from '../WebP';
import { DEFAULT_IMAGE_PRODUCT } from '../../utils/images';

export default {
  name: 'MiniProductCard',
  components: {
    BlCardMv,
    BlImage,
    WebP,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageProduct() {
      const { product } = this;
      const imageProduct =
        product.images.large_urls && product.images.large_urls.length
          ? product.images.large_urls[0]
          : DEFAULT_IMAGE_PRODUCT;
      return imageProduct.replace('/large/', '/w-200/');
    },
    productUrl() {
      return this.product.url;
    },
    hasDeal() {
      const { deal } = this.product;
      if (deal && deal.applied_date && deal.expired_date) {
        const validAppliedDate = Date.parse(deal.applied_date) <= Date.now();
        const validExpiredDate = Date.parse(deal.expired_date) > Date.now();
        return validAppliedDate && validExpiredDate;
      }
      return false;
    },
  },
};
</script>
<style src="./MiniProductCard.style.scss" lang="scss" scoped></style>
