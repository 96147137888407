/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  ico_check_minor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data:
      '<path pid="0" d="M16.78 5.62l-8 10a1 1 0 0 1-.72.38H8a1 1 0 0 1-.71-.29l-4-4a1 1 0 0 1 1.42-1.42l3.21 3.21 7.3-9.12a1 1 0 0 1 1.56 1.24z"/>',
  },
});
